<template>
  <a-modal
    :visible="visible"
    @cancel="closeModal"
    :footer="null"
    class="feed-upload-modal"
    :title="null"
  >
    <div v-if="uploadMedia" class="upload-media modal-content">
      <div class="modal-content__header">
        <h2 class="modal-content__header--title">
          Upload Content
        </h2>
      </div>
      <div class="modal-content__body">
        <div class="modal-content__body--textarea">
          <textarea
            v-model="textContent"
            rows="1"
            placeholder="Write your thoughts to collaborate to Glocal Feed...."
          ></textarea>
        </div>
        <a-upload
          :multiple="false"
          v-model:file-list="fileListMedia"
          list-type="picture-card"
          accept=".jpg, .jpeg, .png, .webp, .bmp, .pdf, .mp4, .mp3"
          :before-upload="beforeUploadMedia"
        >
          <div class="upload-btn" v-if="fileListMedia.length < 1">
            <div class="upload-btn__icon">
              <img src="@/assets/icons/add_photo.svg" />
            </div>
            <h4 class="upload-btn__title">Add Content</h4>
            <p class="upload-btn__drag">or drag and drop</p>
          </div>
        </a-upload>
      </div>
      <div class="modal-content__footer">
        <a-button
          @click="submitPost"
          :class="{
            'disable-save-btn':
              (fileListMedia.length < 1 &&
                textContent.length < 1 &&
                mediaId === null) ||
              isUploading === true,
          }"
          :loading="createFeedLoader"
          >Post</a-button
        >
      </div>
    </div>
    <!-- <div v-if="uploadVideo" class="upload-video modal-content">
      <div class="modal-content__header">
        <h2 class="modal-content__header--title">
          Upload Video
        </h2>
      </div>
      <div class="modal-content__body">
        <div class="modal-content__body--textarea">
          <textarea
            v-model="textContent"
            rows="1"
            placeholder="Write your thoughts to collaborate to Glocal Feed...."
          ></textarea>
        </div>
        <a-upload
          v-model:file-list="fileListVideo"
          list-type="picture-card"
          @change="handleChangeMedia"
          accept=".mp4"
          :before-upload="beforeUploadMedia"
          :remove="deletefileList"
        >
          <div class="upload-btn" v-if="fileListVideo.length < 1">
            <div class="upload-btn__icon">
              <img src="@/assets/icons/add_photo.svg" />
            </div>
            <h4 class="upload-btn__title">Add video</h4>
            <p class="upload-btn__drag">or drag and drop</p>
          </div>
        </a-upload>
      </div>
      <div class="modal-content__footer">
        <a-button
          @click="submitPost('video')"
          :class="{
            'disable-save-btn':
              fileListVideo.length < 1 && textContent.length < 1 && mediaId === null,
          }"
          :loading="createFeedLoader"
          >Post</a-button
        >
      </div>
    </div>
    <div v-if="uploadArticleLink" class="upload-article-link modal-content">
      <div class="modal-content__header">
        <h2 class="modal-content__header--title">
          Upload Article Link
        </h2>
      </div>
      <div class="modal-content__body">
        <input
          type="text"
          class="modal-content__body--input"
          placeholder="Add article link"
        />
      </div>
      <div class="modal-content__footer">
        <a-button>Post</a-button>
      </div>
    </div> -->
  </a-modal>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      mediaId: null,
      fileListMedia: [],
      fileListVideo: [],
      loading: false,
      textContent: "",
      isUploading: false,
      createFeedLoader: false,
      fileType: "text",
      shouldRemoveErrorClass: false,
    };
  },
  props: {
    visible: Boolean,
    uploadMedia: Boolean,
    uploadVideo: Boolean,
    uploadArticleLink: Boolean,
    text:{
      type: String,
      default: () => ('')
    }
  },
  emits: ["closeFeedUploadModal", 'emptyParentText'],
  watch:{
    visible:{
      handler(isOpen){
        if(isOpen){
            this.textContent = this.text
        }
      },immediate:true
    }
  },
  methods: {
    ...mapActions("feed", ["createFeed", "fetchFeedList", "updateFeedPageSize"]),
    closeModal() {
      this.$emit("closeFeedUploadModal", false);
      this.videoUrl = "";
      this.fileListMedia = [];
      this.fileListVideo = [];
      this.loading = false;
      this.textContent = "";
      this.mediaId = null;
      this.createFeedLoader = false;
    },
    beforeUploadMedia(file) {
      return false;
    },

    async handleChangeMedia(info) {
      if (this.isUploading) return;
      this.isUploading = true;

      try {
        // Since fileList is not available, we can directly work with the `info` object.
        const file = info.originFileObj || info.file;

        if (file) {
          const form = new FormData();
          form.append("file", file);

          // Determine the file type
          const fileType = this.checkFileType(file);

          const response = await this.$store.dispatch(
            "feed/uploadFeedPhotoMedia",
            form
          );
          this.mediaId = response.data.id;
          this.fileType = fileType;
        } else {
          console.error("No file found to upload.");
        }
      } catch (error) {
        console.error("Upload failed", error);
      } finally {
        this.isUploading = false;
      }
    },
    // async handleChangeMedia(info) {
    //   console.log('info',info);

    //   if (this.isUploading) return;
    //   this.isUploading = true;
    //   try {
    //     this.file = info;

    //     const isAcceptableFileType =
    //       info.type === "image/jpeg" ||
    //       info.type === "image/png" ||
    //       info.type === "image/jpg" ||
    //       info.type === "image/webp" ||
    //       info.type === "image/svg+xml" ||
    //       info.type === "video/mp4"||
    //       info.type === "image/jpeg"||
    //        info.type ==="image/png"||
    //        info.type === "image/jpg"||
    //        info.type ==="image/webp"||
    //        info.type ==="image/svg+xml"||
    //        info.type === "image/gif"||
    //        info.type ==="image/bmp"||
    //        info.type ==="application/pdf"||
    //        info.type === "application/msword"||
    //        info.type ==="application/vnd.openxmlformats-officedocument.wordprocessingml.document"||
    //        info.type ==="video/mp4"||
    //        info.type === "video/x-msvideo"||
    //        info.type === "video/quicktime"||
    //        info.type ==="video/x-matroska"||
    //        info.type === "audio/mpeg"||
    //        info.type ==="audio/wav"||
    //        info.type === "audio/ogg"

    //     if (isAcceptableFileType) {
    //         const files = info.fileList;
    //         const form = new FormData();
    //         files.forEach((val) => {
    //           form.append("file", val.originFileObj);
    //         });

    //         if (info.fileList.length > 0) {
    //           const response = await this.$store.dispatch(
    //             "feed/uploadFeedPhotoMedia",
    //             form
    //           );
    //           this.mediaId = response.data.id;
    //           this.fileType = this.checkFileType(info)
    //           this.isUploading = false;
    //         }
    //     }
    //   } catch (error) {
    //     console.error("Upload failed", error);
    //   } finally {
    //     this.isUploading = false;
    //   }
    // },
    // async deletefileList() {
    //   try {
    //     await this.$store.dispatch("feed/deleteFeedPhotoMedia", this.mediaId);
    //     this.mediaId = null;
    //   } catch (error) {
    //     console.error("Deletion failed", error);
    //   }
    // },
    async submitPost() {
      try {
        this.createFeedLoader = true;

        if (this.fileListMedia.length > 0) {
          await this.handleChangeMedia(
            this.fileListMedia[this.fileListMedia.length - 1]
          );
        }
        const payload = {
          text: this.textContent,
          files: this.mediaId ? [this.mediaId] : [],
          article: null,
          shared: null,
          content_type: this.mediaId ? this.fileType : "text",
        };
        await this.createFeed(payload);
        this.updateFeedPageSize(1);
        await this.fetchFeedList();
        this.$emit('emptyParentText')
        this.closeModal();
      } catch (error) {
        console.error("Error creating post:", error);
      } finally {
        this.createFeedLoader = false;
      }
    },

    // async submitPost() {
    //   this.createFeedLoader = true;
    //   if (this.fileListMedia.length > 0) {
    //     await this.handleChangeMedia(
    //       this.fileListMedia[this.fileListMedia.length - 1]
    //     );
    //   }

    //   const payload = {
    //     text: this.textContent,
    //     files: this.mediaId ? [this.mediaId] : [],
    //     article: null,
    //     shared: null,
    //     content_type: this.mediaId ? this.fileType : "text",
    //   };
    //   this.createFeed(payload)
    //     .then(() => {
    //       this.createFeedLoader = false;
    //       this.fetchFeedList();
    //       this.closeModal();
    //     })
    //     .catch((error) => {
    //       console.error("Error creating post:", error);
    //     });
    // },
    //  async submitPost() {
    //   await this.handleChangeMedia(this.fileListMedia);
    //   console.log('fileListMedia',this.fileListMedia);

    //     const payload = {
    //       text: this.textContent,
    //       files: this.mediaId ? [this.mediaId] : [],
    //       article: null,
    //       shared: null,
    //       content_type: this.mediaId ? this.fileType : 'text'
    //     };

    //     this.createFeedLoader = true;
    //     this.createFeed(payload)
    //       .then(() => {
    //         this.createFeedLoader = false;
    //         this.closeModal();
    //         this.fetchFeedList();
    //       })
    //       .catch((error) => {
    //         console.error("Error creating post:", error);
    //       });
    //   },

    // submitPost() {
    //   const payload = {
    //       text: this.textContent,
    //       files: this.mediaId ?  [this.mediaId] : [],
    //       article: null,
    //       shared: null,
    //       content_type: this.mediaId ? this.fileType : 'text'
    //   };
    //     console.log('this.fileType',this.fileType);

    //   // this.createFeedLoader = true
    //   // this.createFeed(payload)
    //   //   .then(async() => {
    //   //     this.createFeedLoader = false
    //   //     this.closeModal();
    //   //     this.fetchFeedList()
    //   //   })
    //     // .catch((error) => {
    //     //   console.error("Error creating post:", error);
    //     // });
    // },
    // checkFileType(file) {
    //   const isimage =
    //       file.type === "image/jpeg" ||
    //       file.type === "image/png" ||
    //       file.type === "image/jpg" ||
    //       file.type === "image/webp" ||
    //       file.type === "image/svg+xml" ||
    //       file.type ==="image/jpeg"||
    //       file.type === "image/png"||
    //       file.type === "image/jpg"||
    //       file.type === "image/webp"||
    //       file.type === "image/gif"||
    //       file.type === "image/bmp"||
    //       file.type ==="application/pdf"||
    //       file.type === "application/msword"||
    //       file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"||
    //       file.type === "video/mp4"||
    //       file.type === "video/x-msvideo"||
    //       file.type === "video/quicktime"||
    //       file.type ===  "video/x-matroska"||
    //       file.type === "audio/mpeg"||
    //       file.type === "audio/wav"||
    //       file.type === "audio/ogg"

    //     if (isimage)
    //       return 'images'
    //     else if ( file.type === "video/mp4" )
    //       return 'videos'
    // },
    checkFileType(file) {
      const imageTypes = [
        "image/jpeg",
        "image/png",
        "image/jpg",
        "image/webp",
        "image/svg+xml",
        "image/gif",
        "image/bmp",
      ];

      const documentTypes = [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ];

      const videoTypes = [
        "video/mp4",
        "video/x-msvideo",
        "video/quicktime",
        "video/x-matroska",
      ];

      const audioTypes = ["audio/mpeg", "audio/wav", "audio/ogg"];

      if (imageTypes.includes(file.type)) return "image";
      if (documentTypes.includes(file.type)) return "document";
      if (videoTypes.includes(file.type)) return "video";
      if (audioTypes.includes(file.type)) return "audio";

      return "unknown";
    },
  },
};
</script>

<style lang="scss">
.feed-upload-modal {
  width: 50rem !important;
  top: 10rem !important;
  .ant-modal-content {
    box-shadow: 0px 0px 94px #00000047;
    .ant-modal-close {
      margin: 2.4rem 1.7rem 0 0;
    }
    .ant-modal-body {
      .modal-content {
        border-radius: 1rem;
        &__header {
          padding: 2.5rem;
          &--title {
            font-size: 2.4rem;
            font-family: $font-primary-bold;
            line-height: normal;
            margin-bottom: 0;
            color: $color-black;
          }
        }
        &__body {
          padding: 0;
          margin: 0 2.5rem;
          max-height: calc(100vh - 35rem);
          overflow-y: auto;
          &--textarea {
            margin-bottom: 1rem;
            textarea {
              padding: 0;
              border: none;
              color: $color-black;
              font-size: 1.4rem;
              font-family: $font-primary;
              height: fit-content;
              width: 100%;
              outline: none;
              border-radius: 5px !important;
              margin-bottom: 1.5rem;
              background-color: transparent;
              resize: none;
              display: inline-table;
              &::placeholder {
                color: $color-black;
                opacity: 0.5;
              }
            }
          }
          &--input {
            height: 4rem;
            padding: 1.2rem 1rem;
            border: 1px solid $color-dark-grey-5;
            outline: none;
            border-radius: 5px !important;
            width: 100%;
            font-size: 1.4rem;
            font-family: $font-primary;
            color: $color-black;
            margin-bottom: 1rem;
            background-color: $color-white;
            &::placeholder {
              color: #949494;
            }
          }
          .ant-upload-picture-card-wrapper {
            .ant-upload-list {
              .ant-upload-list-picture-card-container {
                width: 100%;
                height: 100%;
                margin: 0 auto;
                .ant-upload-list-item {
                  width: 100%;
                  height: 25rem;
                  margin: 0;
                  padding: 0;
                  border: none !important;
                  .ant-upload-list-item-info {
                    width: 100%;
                    height: 100%;
                    .ant-upload-list-item-name {
                      color: $color-black;
                      font-size: 1.6rem;
                      line-height: 2.2rem;
                      opacity: 0.7;
                    }
                  }
                  .ant-upload-list-item-actions {
                    pointer-events: all;
                    a {
                      display: none;
                    }
                    .anticon-delete {
                      font-size: 3rem;
                    }
                  }
                }
              }
              .ant-upload-list-picture-card-container > span {
                width: 100%;
                overflow-x: hidden
              }
            }
            .ant-upload-select {
              margin: 0;
              width: 100%;
              border: 1px dashed $color-dark-grey-5;
              .ant-upload {
                padding: 4rem 2rem;
                .upload-btn {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  &__icon {
                    background-color: rgb(228, 230, 235);
                    height: 5rem;
                    width: 5rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 100%;
                    margin-bottom: 1rem;
                    img {
                      width: 3rem;
                    }
                  }
                  &__title {
                    font-size: 1.7rem;
                    font-family: $font-primary-medium;
                    line-height: normal;
                    margin-bottom: 0.5rem;
                    color: $color-black;
                  }
                  &__drag {
                    font-size: 1.4rem;
                    font-family: $font-primary;
                    line-height: normal;
                    margin-bottom: 0;
                    color: $color-black;
                    opacity: 0.6;
                  }
                }
              }
              &:hover {
                border-color: $color-faded-blue;
              }
            }
            .ant-upload-list-picture-card {
              .ant-upload-list-picture-card-container {
                display: flex;
                align-items: center;
                justify-content: center;
                .ant-upload-list-item-uploading {
                  width: 100%;
                  margin: 0 auto 1rem;
                  display: block;
                  .ant-upload-list-item-info {
                    span {
                      width: 15rem;
                      .ant-upload-list-item-uploading-text {
                        font-size: 1.4rem;
                        font-family: $font-primary;
                        line-height: normal;
                        margin-bottom: 0;
                        color: $color-black;
                        opacity: 0.6;
                      }
                    }
                  }
                  .ant-upload-list-item-progress {
                    .ant-progress {
                      div {
                        .ant-progress-outer {
                          .ant-progress-inner {
                            .ant-progress-bg {
                              background-color: $color-primary;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              .ant-upload-list-picture-card-container > span {
                width: 100%;
                overflow-x: hidden
              }
            }
          }
          &::-webkit-scrollbar {
            width: 0.4rem;
            height: 0.4rem;
          }

          &::-webkit-scrollbar-track {
            background-color: transparent;
            border-radius: 0.8rem;
          }

          &::-webkit-scrollbar-thumb {
            background-color: $color-primary;
            border-radius: 0.8rem;
            width: 0.4rem;
            height: 0.4rem;
          }
        }
        &__footer {
          padding: 2.5rem 2.5rem;
          .ant-btn {
            width: 10rem;
            background-color: $color-primary;
            border: none;
            outline: none;
            height: 4rem;
            font-size: 1.4rem;
            font-family: $font-primary-medium !important;
            color: $color-white;
            margin-left: auto;
            display: block;
            span {
              line-height: normal;
            }
            .anticon {
              .anticon-spin {
                font-size: 1.8rem;
                color: $color-primary;
              }
            }
            &:hover,
            &:focus {
              background-color: $color-primary;
              color: $color-white;
            }
          }
          .disable-save-btn {
            pointer-events: none;
            opacity: 0.5;
          }
        }
      }
    }
  }
}
</style>
