<template>
  <a-modal
    :visible="visible"
    :footer="null"
    @cancel="closeModal"
    width="60rem"
    class="recovery-email-modal"
  >
    <div class="recovery-email-modal-container">
      <div class="recovery-email-modal-container__header">
        <img src="@/assets/icons/inbox-icon.svg" alt="Email Logo" />
      </div>
      <div class="recovery-email-modal-container__body">
        <h1 class="title">
          Add a recovery email for your account
        </h1>
        <p class="para">
          We recommend you to add a recovery email for your account to help you
          regain access to your account in case you forget your password or are
          locked out of your account for another reason.
        </p>
        <form @submit.prevent="submitForm">
          <input
            type="email"
            class="input"
            required
            placeholder="Add recovery email"
            v-model.trim="email"
          />
          <p v-if="error" class="error">
            {{ error }}
          </p>
          <p class="para text-left">
            You can always update this email from your profile in account
            settings.
          </p>
          <a-button
            @click="submitForm()"
            class="primary-btn"
            :loading="loading"
          >
            Submit
          </a-button>
          <a-button @click="skip()" class="skip-btn">
            Skip for now
          </a-button>
        </form>
      </div>
    </div>
  </a-modal>
  <message-toaster
    :visible="showMessageModal"
    :message="message"
    @handle-close="showMessageModal = false"
  >
  </message-toaster>
</template>

<script>
import MessageToaster from '../BaseComponents/MessageToaster.vue';
export default {
  props: {
    visible: Boolean,
  },
  data() {
    return {
      email: "",
      error: "",
      loading: false,
      showMessageModal: false,
      message: {},
    };
  },
  components: {
    MessageToaster
  },
  emits: ["close-modal"],
  computed: {
    userProfile() {
      return this.$store.getters['profile/userInformation'];      
    },
  },
  methods: {
    closeModal() {
      this.$emit("close-modal");
    },
    async submitForm() {
      try {
        if (this.email === "") {
          this.error = "This field is required";
          return false;
        }
        this.loading = true;
        this.error = "";
        const payload =
        {
          email: this.userProfile.email,
          secondary_email: this.email,
          profile: {
            full_name: this.userProfile.profile.full_name
        }
      }

        const response = await this.$store.dispatch(
          "profile/updateSecondaryEmail",
          payload
        );
        if (response.status === 200) {
          this.emitter.emit('closeEmailPopup');
        }
        this.$showToast({message:"Added Recovery Email Successfully!", type:'success'})
      } catch (err) {
        if (err?.response?.data?.secondary_email)
          this.error = err?.response?.data?.secondary_email[0]
      }
      finally{
        this.loading = false;
      }
    },
    skip() {
      this.emitter.emit('closeEmailPopup');
      this.closeModal();
    },
  },
};
</script>

<style lang="scss">
.recovery-email-modal {
  top: 15rem !important;
  .ant-modal-content {
    .ant-modal-close {
      display: none;
    }
    .ant-modal-body {
      padding: 0;
      .recovery-email-modal-container {
        padding: 3.9rem 2.4rem;
        &__header {
          margin-bottom: 5.6rem;
          img {
            width: 7.4rem;
            height: auto;
            display: block;
            margin: 0 auto;
          }
        }
        &__body {
          .title {
            font-family: $font-primary-bold;
            font-size: 2.8rem;
            line-height: 3.7rem;
            margin-bottom: 1.6rem;
            text-align: center;
          }
          .para {
            font-family: $font-primary;
            font-size: 1.6rem;
            line-height: 2.1rem;
            margin-bottom: 0;
            margin-top: 0.8rem;
            text-align: center;
            color: #696969;
          }
          .text-left {
            text-align: left;
          }
          form {
            .input {
              height: 4.6rem;
              width: 100%;
              border: 1px solid $color-dark-grey-5;
              padding: 0 1.4rem;
              color: $color-black;
              outline: none;
              font-family: $font-primary;
              font-size: 1.6rem;
              margin: 3.2rem 0 0.8rem;
              &::placeholder {
                opacity: 0.8;
              }
            }
            .error {
              color: red;
              font-size: 1.4rem;
              font-family: $font-primary;
            }
            .primary-btn {
              color: $color-white;
              font-size: 1.6rem;
              font-family: $font-primary-medium;
              border: none;
              outline: none;
              background-color: $color-primary;
              width: 100%;
              margin-top: 3.2rem;
              height: 4.8rem;
              border-radius: 5px !important;
              cursor: pointer;
            }
            .skip-btn {
              color: #696969;
              font-size: 1.6rem;
              font-family: $font-primary;
              border: none;
              outline: none;
              background-color: transparent;
              width: fit-content;
              padding: 0;
              line-height: normal;
              margin-top: 3.2rem;
              height: fit-content;
              border-radius: 5px !important;
              cursor: pointer;
              margin: 1.6rem auto 0;
              display: block;
            }
          }
        }
      }
    }
  }
}
</style>
