<template>
  <transition name="slide-fade">
    <div v-if="visible" class="message-toaster">
      <div class="message-toaster__header">
        <img
          v-if="toastType == 'success'"
          src="@/assets/icons/success-icon.svg"
          class="message-toaster__header--image"
        />
        <img
          v-else
          src="@/assets/icons/info-icon.svg"
          class="message-toaster__header--image"
        />
        <!-- <img
          src="@/assets/icons/error-icon.svg"
          class="message-toaster__header--image"
        /> -->
        <h2 class="message-toaster__header--title">{{ toastMessage }}</h2>
        <button @click="closeToaster()" class="message-toaster__header--btn">
          <i class="icon icon-add_plus"></i>
        </button>
      </div>
      <div class="message-toaster__body">
        <h3 class="message-toaster__body--time">Today {{ currentTime }}</h3>
        <!-- <h3 class="message-toaster__body--time">Today 10:30PM</h3> -->
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  props: {
    // visible: Boolean,
    message:{
      type:Object,
      default:()=>({
       
          type:'success',
          title:'Dummy Message'
        
      })
    }
  },
  data() {
    return {
      currentTime: null
    };
  },
  computed:{
    ...mapGetters({
      toastMessage: 'toast/message',
      toastType: 'toast/type',
      visible: 'toast/visible'
    })
  },
  watch: {
    visible(newValue) {
      if (newValue) {
        setTimeout(() => {
          this.closeToaster();
        }, 3000); 
      }
    }
  },
  emits: ["handle-close"],
  methods: {
    ...mapActions({
      toggleOffToastVisibility: 'toast/toggleOffToastVisibility'
    }),
    closeToaster() {
      this.toggleOffToastVisibility()
    },
    updateTime() {
      const date = new Date();
      const options = { hour: 'numeric', minute: 'numeric', hour12: true };
      const timeString = date.toLocaleString('en-US', options);
      this.currentTime = timeString;
    }
  },
  mounted() {
    // Update the time initially
    this.updateTime();

    // Update the time every second
    setInterval(() => {
      this.updateTime();
    }, 1000);
  }
}
</script>

<style lang="scss" scoped>
.message-toaster {
  position: fixed;
  right: 4rem;
  z-index: 100;
  overflow: hidden;
  top: 9rem;
  background-color: $color-black;
  padding: 1.5rem 2rem;
  border-radius: 1.2rem;
  width: 32rem;
  &__header {
    display: flex;
    align-items: flex-start;
    &--image {
      width: 2rem;
      height: auto;
      margin-right: 1.8rem;
      margin-top: 0.5rem;
    }
    &--title {
      font-size: 1.6rem;
      font-family: $font-primary-medium;
      margin-bottom: 0;
      color: $color-white;
    }
    &--btn {
      margin-left: auto;
      display: block;
      height: fit-content;
      width: fit-content;
      line-height: 0;
      border: none;
      outline: none;
      background-color: transparent;
      opacity: 0.8;
      transition: all 0.3s;
      cursor: pointer;
       margin-top: 0.5rem;
      .icon {
        transform: rotate(45deg);
        display: block;
        color: $color-white;
        font-size: 1.2rem;
      }
      &:hover {
        opacity: 1;
      }
    }
  }
  &__body {
    padding-left: 3.8rem;
    &--time {
      font-size: 1.2rem;
      font-family: $font-primary;
      margin-bottom: 0;
      color: #9faed7;
    }
  }
}
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
