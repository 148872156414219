<template>
  <div class="profile-page-container">
    <a-row :gutter="[18, 18]">
      <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <TheUserInformation />
        <UserOthersInformation />
      </a-col>
    </a-row>
  </div>
</template>

<script>
import TheUserInformation from "./TheUserInformation.vue";
import UserOthersInformation from "./UserOthersInformation.vue";
import {mapGetters, mapActions} from 'vuex'
export default {
  components: {
    TheUserInformation,
    UserOthersInformation,
  },
  data:()=>({
    isSharedProfileView: false
  }),
  computed:{
    ...mapGetters({})
  },
  watch:{
    '$route.params':{
      async handler(val){
        if (this.$route.name === 'share-user-profile' || this.$route.name === 'user-profile')
          await this.getUserInformation(val)
      }
    },
    '$route':{
      handler(to, from){
        this.isSharedProfileView = to.name === 'share-user-profile' ? true : false
        this.isSharedProfileView ? this.toggleShareProfileViewStatus(true) : this.toggleShareProfileViewStatus(false)
      }, immediate: true
    }
  },
  methods:{
    ...mapActions({
      setSelectedUser:'profile/setSelectedUser',
      toggleShareProfileViewStatus: 'profile/toggleShareProfileViewStatus'
    }),
    async getUserInformation(username) {
      try {
        const eventsPayload = {
            page: 1,
            page_size: 8,
          };
        await this.$store.dispatch("profile/fetchUserProfile", username);
        await this.$store.dispatch("profile/loadExperiences");
        await this.$store.dispatch("profile/loadCertificates");
        await this.$store.dispatch("profile/fetchPublications");
        await this.$store.dispatch("profile/getAddedLanguages");
        if (!this.isSharedProfileView){
          await this.$store.dispatch("profile/handleConnctionRequest");
          await this.$store.dispatch("profile/fetchPhotoResume");
          await this.$store.dispatch("location/getTimeZones")
          await this.$store.dispatch("profile/getAllEvent", {
            payload: eventsPayload
          });
        
      }
      } catch (err) {
        console.log(err);
      }
    },

  }
};
</script>

<style lang="scss">
.profile-page-container {
  margin-top: 8rem;
  padding: 2rem 4rem 4rem;
  @include respond(phone-x-small) {
    margin-top: 12.8rem;
  }
}
</style>
