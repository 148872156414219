<template>
  <div class="photo-resume-section">
    <div class="photo-resume-section__header">
      <h2 class="photo-resume-section__header--title">Photos</h2>
      <button
        class="photo-resume-section__header--btn"
        @click="visibleResumeUploadModal()"
        v-if="isLoggedInUser"
        id="addPhotoBtn"
        title="addPhotoBtn"
      >
        <i class="icon icon-add_plus"></i>
      </button>
    </div>
    <div v-if="isResumeLoading" class="photo-resume-section__body">
      <a-row :gutter="[18, 18]">
        <a-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6"
            v-for="photoResumeSkeleton in 8" 
            :key="photoResumeSkeleton"
        >
            <photo-resume-skeleton>
            </photo-resume-skeleton>
        </a-col>
      </a-row>
    </div>
    <div v-else class="photo-resume-section__body">
      <a-row :gutter="[18, 18]">
        <a-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6" 
          v-for="photo in photoResume"
          :key="photo"
        >
          <div
            class="photo-resume-section__body--photo"
          >
            <img :src="photo.media_detail.photo" alt="Resume Photo" height="auto" width="auto" />
            <div class="description" v-if=" photo.description.length > 0">
              <p>{{ photo.description }}</p>
            </div>
            <div class="bg" @click="viewPhotoResume(photo)">
              <a-dropdown :trigger="['click']" v-if="isLoggedInUser">
                <a class="ant-dropdown-link dropdown" @click.stop>
                  <i class="icon icon-vertical_three-dots"></i>
                </a>
                <template #overlay>
                  <a-menu>
                    <a-menu-item key="0">
                      <a @click.stop="openEditModal(photo)">Edit</a>
                    </a-menu-item>
                    <a-menu-item key="1" @click.stop="visibleDeleteModal(photo.id)" :id="photo.id" :title="photo">
                      <a class="remove-btn">Remove</a>
                    </a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
              <div class="eye-icon">
                <img src="@/assets/icons/eye-icon.svg" alt="Preview" />
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
    <p
      v-if="!isResumeLoading && photoResume.length == 0"
      class="photo-resume-section__message"
    >
      No photo found
    </p>
  </div>
  <a-modal
    width="64rem"
    v-model:visible="showUploadImagesModal"
    :footer="null"
    class="photo-resume-upload-modal"
    @cancel="handleEditUploadCancel"

  >
    <div class="upload-photo-container">
      <h2 class="title">Upload Photo</h2>
      <div class="clearfix upload-photo-container__body">
        <template v-if="showPhotoUploadContainer">
          <p v-if="fileError" class="error">Plese select any picture</p>
          <a-upload
            list-type="picture-card"
            :file-list="fileList"
            :before-upload="beforeUpload"
            @preview="handlePreview"
            @change="handleUpload"
            accept=".jpg, .jpeg, .png"
            :remove="removeImage"
          >
            <div class="icon-conatiner">
              <p v-if="onUpload">Uploading...</p>
              <p v-else>Upload</p>
              <the-loader v-if="UploadImageLoader"></the-loader>
            </div>
          </a-upload>
          <p v-if="exceedLimit" class="error">
            You can upload maximum 8 files at once.
          </p>
          <p v-if="largeFileSize" class="error">
            Error uploading file: File is too big. Max filesize: 5MB.
          </p>
          <a-modal
            :visible="previewVisible"
            :footer="null"
            @cancel="handleCancel"
          >
            <img alt="example" style="width: 100%" :src="previewImage" />
          </a-modal>
          <h4>Select a file to upload.</h4>
        </template>
        <div class="upload-photo-container__body--textarea">
          <p>Description</p>
          <textarea
            name="photoResumeDescription"
            id="photoResumeDescription"
            cols="30"
            rows="2"
            v-model="photoResumeDescription"
            placeholder="Add a description for the photo..."
          ></textarea>
        </div>
      </div>
      <div class="upload-photo-container__footer">
        <a-button
          @click="isEditing ? updatePhotoResume() : addPhotoResume()"
          :class="{ 'disable-btn': (isEditing ? photoResumeDescription.length === 0 : !(photoResumeDescription.length > 0 && fileList.length > 0)) }"
          :loading="saveResumeLoding"
        >{{ isEditing ? 'Update' : 'Save' }}</a-button>
      </div>
    </div>
  </a-modal>
  <delete-modal
    :visible="visibleDeleteResumeModal"
    @handle-close="visibleDeleteResumeModal = false"
    title="Are you sure you want to remove this?"
    :message="null"
    confirmText="Yes, Remove"
    cancelText="No, Don't Remove"
    :loading="loader"
    @delete-record="deleteResume(selectedImage)"
  ></delete-modal>
  <a-modal
    v-model:visible="showPhotoResumeModal"
    :footer="null"
    class="photo-resume-view-modal"
  >
    <div class="view-photo">
      <img :src="viewImage" />
    </div>
    <div class="description">
      <p>{{ viewDescription }}</p>
    </div>
  </a-modal>
  <a-modal
    v-model:visible="showModalError"
    :footer="null"
    @cancel="()=> {showModalError = false}"
    class="show-photo-resume-lenght-error-modal"
  >
    <div class="show-photo-resume-lenght-error-modal-container">
      <img src="@/assets/icons/error-icon.svg" />
      <h1>Maximum photo limit: 16 photos. Please delete photos to upload more.</h1>
    </div>
  </a-modal>
</template>

<script>
function getBase64(file, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(file);
}
import PhotoResumeSkeleton from "./SkeletonLoaders/PhotoResumeSkeletonLoader.vue";
import DeleteModal from "../BaseComponents/DeleteModal.vue";
import TheLoader from "../BaseComponents/TheLoader.vue";
export default {
  data() {
    return {
      showUploadImagesModal: false,
      fileError: false,
      fileList: [],
      exceedLimit: false,
      previewVisible: false,
      previewImage: "",
      visibleDeleteResumeModal: false,
      loader: false,
      selectedImage: null,
      saveResumeLoding: false,
      photoResumeSkeleton: false,
      showPhotoResumeModal: false,
      viewImage: null,
      viewDescription: null,
      imageIds: [],
      largeFileSize: false,
      UploadImageLoader: false,
      actionUpload: false,
      onUpload: false,
      showModalError: false,
      photoResumeDescription: '',
      isEditing: false,
      showPhotoUploadContainer: true,
      selectedPhotoId: -1
    };
  },
  components: {
    PhotoResumeSkeleton,
    DeleteModal,
    TheLoader,
  },
  computed: {
    isResumeLoading() {
      return this.$store.getters["profile/getPhotoResumeSkeletonLoader"];
    },
    getUserInfo(){
      return this.$store.getters["profile/getSelectedUserProfile"];
    },
    photoResume() {
      return this.$store.getters["profile/getPhotoResume"];
    },
    isLoggedInUser() {
      return this.$store.getters["profile/isLoggedinUser"];
    },
  },
  methods: {
    visibleResumeUploadModal() {
      this.showPhotoUploadContainer = true
      this.photoResumeDescription = ''
      if (this.photoResume.length < 16) {
        this.showUploadImagesModal = true
        this.showModalError = false
      } else {
          this.showUploadImagesModal = false
          this.showModalError = true
      }
    },
    async uploadPhotoResume() {
      this.photoResumeSkeleton = true;
      try {
        const payload = {
          user_id: this.userId,
        };
        await this.$store.dispatch("profile/fetchPhotoResume", payload);
      } catch (err) {
        console.log(err);
      }
      this.photoResumeSkeleton = false;
    },
    async addPhotoResume() {
      this.saveResumeLoding = true;
      try {
        const payload = this.imageIds.map((val) => {
          return {
            photo: val,
            description: this.photoResumeDescription
          };
        });
        const response = await this.$store.dispatch("profile/postPhotoResume", payload);
        if (response.status == 201) {
          this.showUploadImagesModal = false;
          this.saveResumeLoding = false;
          this.fileList = [];
          this.fileError = false;
          this.imageIds = [];
          this.largeFileSize = false;
          this.actionUpload = false;
          this.onUpload = false;
          this.exceedLimit = false;
          this.photoResumeDescription = ""; // Clear the description after successful upload
          this.$showToast({message:'Image uploaded successfully.', type:'success'})
        }
      } catch (err) {
        console.log(err);
        this.$showToast({message:'Something went wrong. Please try again later', type:'failure'})
      }
    },
    async updatePhotoResume() {
      this.saveResumeLoding = true;
      try {
        const payload = {
          description: this.photoResumeDescription
        }
        const response = await this.$store.dispatch("profile/editPhotoResume", {
          photoId:this.selectedPhotoId,
          payload
        });
        if (response.status == 200) {
          this.showUploadImagesModal = false;
          this.saveResumeLoding = false;
          this.isEditing = false
          this.$showToast({message:'Image updated successfully.', type:'success'})
        }
      } catch (err) {
        console.log(err);
        this.$showToast({message:'Something went wrong. Please try again later.', type:'failure'})
      }
    },
    removeImage(file) {
      const index = this.fileList.indexOf(file);
      if (index !== -1) {
        this.fileList.splice(index, 1);
        this.imageIds.splice(index, 1);
      }
    },
    async handleUpload({ fileList }) {
      this.exceedLimit = false;
      if (fileList.length > 8) {
        this.exceedLimit = true;
        return;
      }
      fileList.forEach((element) => {
        if (element.size >= 5000000) {
          this.largeFileSize = true;
          this.actionUpload = false;
        } else {
          this.largeFileSize = false;
        }
      });
      try {
        if (this.actionUpload == true) {
          this.UploadImageLoader = true;
          this.onUpload = true;
        }
        if (this.largeFileSize == false) {
          const form = new FormData();
          fileList.forEach((val) => {
            form.append("photo", val.originFileObj);
          });
          const response = await this.$store.dispatch(
            "profile/uploadPhotoCheck",
            form
          );
          if (response.status == 201) {
            if (response.data) {
              this.fileList = fileList;
              this.imageIds.push(response.data.id);
              this.actionUpload = false;
              this.onUpload = false;
            }
          }
          this.UploadImageLoader = false;
        }
      } catch (err) {
        console.log(err);
      }
    },
    beforeUpload(file) {
      this.actionUpload = true;
      this.exceedLimit = false;
      this.file = file;
      return false;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj, (imageUrl) => {
          this.previewImage = imageUrl;
        });
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleCancel() {
      this.previewVisible = false;  
    },
    handleEditUploadCancel(){
       this.isEditing = false
    },
    async deleteResume(id) {
      this.loader = true;
      try {
        const payload = {
          id: id,
        };
        const response = await this.$store.dispatch(
          "profile/deletePhotoResume",
          payload
        );
        if (response.status == 204) {
          this.loader = false;
          this.visibleDeleteResumeModal = false;
          this.$showToast({message:'Image removed successfully.', type:'success'})
        }
      } catch (err) {
        this.$showToast({message:'Something went wrong. Please try again later', type:'failure'})
        // console.log(err);
      }
    },
    visibleDeleteModal(object) {
      this.visibleDeleteResumeModal = true;
      this.selectedImage = object;
    },
    viewPhotoResume(photo) {
      this.viewImage = photo.media_detail.photo;
      this.viewDescription = photo.media_detail.description
      this.showPhotoResumeModal = true;
    },
    openEditModal(photo) {
      this.ellipseDropdown = false
      this.isEditing = true;
      this.showPhotoUploadContainer = false
      this.photoResumeDescription = photo.description || '';
      this.selectedPhotoId = photo.id
      this.showUploadImagesModal = true;
    }
  },
};
</script>

<style lang="scss">
.photo-resume-section {
  padding: 0 2.6rem 3rem;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &--title {
      font-size: 2rem;
      font-family: $font-primary-medium;
      margin-bottom: 0;
      line-height: 2.3rem;
      color: $color-black;
      @include respond(tab-port) {
        font-size: 2.2rem;
        line-height: 2.8rem;
      }
    }
    &--btn {
      background-color: $color-primary;
      height: 4.2rem;
      width: 4.2rem;
      border: none;
      outline: none;
      border-radius: 100% !important;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-left: auto;
      .icon {
        color: $color-white;
        font-size: 1.6rem;
      }
    }
  }
  &__body {
    margin-top: 2.6rem;
    overflow: hidden;
    &--photo {
      width: 100%;
      height: 34.7rem;
      background-color: $color-dark-gray-4;
      border-radius: 1rem;
      position: relative;
      img {
        width: inherit;
        height: 100%;
        object-fit: cover;
        border-radius: 1rem;
      }
      .description {
        position: absolute;
        bottom: 0;
        background-color:  rgba(0, 0, 0, 0.678);
        padding: 1rem;
        border-end-end-radius: 1rem;
        border-end-start-radius: 1rem;
        width: 100%;
        min-height: 8.6rem;
        p {
          font-size: 1.6rem;
          font-family: $font-primary;
          margin-bottom: 0;
          color: $color-white;
          line-height: 2.2rem;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3; /* number of lines to show */
                  line-clamp: 3; 
          -webkit-box-orient: vertical;
          @include respond(tab-port) {
            font-size: 1.8rem;
            line-height: 2.4rem;
          }
        }
      }
      .bg {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.596);
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        cursor: pointer;
        border-radius: 1rem;
        transition: opacity 0.2s ease-in-out;
        opacity:0;
        .eye-icon {
          img {
            width: rem;
          }
        }
        .dropdown {
          width: 3.5rem;
          height: 3.5rem;
          border-radius: 100% !important;
          cursor: pointer;
          background-color: $color-white;
          border: none;
          outline: none;
          margin: 1rem;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          right: 0;
          top: 0;
          .icon {
            color: $color-black;
            font-size: 1.5rem;
          }
        }
      }
      &:hover {
        .bg {
          opacity: 1;
        }
      }
    }
  }
  &__message {
    font-size: 1.6rem;
    font-family: $font-primary;
    margin-bottom: 0;
    line-height: 2.3rem;
    color: $color-black;
    @include respond(tab-port) {
      font-size: 1.8rem;
      line-height: 2.2rem;
    }
  }
}
.photo-resume-upload-modal {
  top: 10rem;
  .ant-modal-content {
    padding: 0;
    .ant-modal-close {
      margin: 2.6rem 2.2rem 0 0;
    }

    .ant-modal-body {
      .upload-photo-container {
        .title {
          font-family: $font-secondary-bold;
          font-size: 2.2rem;
          margin-bottom: 3rem;
          line-height: normal;
          border-bottom: 1px solid rgb(200, 200, 200);
          padding: 2.8rem 3rem;
        }

        &__body {
          display: flex;
          width: 100%;
          justify-content: space-between;
          flex-direction: column;
          padding: 0 3rem;

          .ant-upload-picture-card-wrapper {
            display: flex;
            align-items: center;
            flex-direction: column;

            .ant-upload-list {
              margin: 2rem 0;

              .ant-upload-list-picture-card-container {
                width: 10rem;
                height: 10rem;
                margin: 0 2rem 2rem;

                .ant-upload-list-item {
                  width: 10rem;
                  height: 10rem;
                  margin: 0;
                  border-radius: 0;
                  border: 1px solid rgb(213, 213, 213);
                  .ant-upload-list-item-info {
                    background-color: rgb(0, 0, 0);
                    .ant-upload-list-item-name {
                      font-family: $font-secondary;
                      font-size: 1.4rem;
                      color: $color-black;
                      opacity: 0.6;
                    }
                    &::before {
                      background-color: transparent;
                    }
                  }
                }
                .ant-upload-list-item-list-type-picture-card {
                  // cursor: initial !important;
                  // .ant-upload-list-item-actions {
                  //   display: none !important;
                  // }
                }
              }
            }

            .ant-upload-select {
              width: fit-content;
              height: fit-content;
              border: none;
              margin: 0 auto;

              .ant-upload {
                width: fit-content;
                height: fit-content;
                padding: 0;
                background-color: #ebebeb;

                .icon-conatiner {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  flex-direction: column;
                  padding: 1rem 2rem;
                  border: 1px solid #8080807a;
                  position: relative;
                  .icon {
                    margin-bottom: 0;
                    font-size: 1.4rem;
                    color: $color-black;
                  }

                  p {
                    font-family: $font-primary-medium;
                    font-size: 1.6rem;
                    color: $color-black;
                    opacity: 0.8;
                  }
                  .ant-spin {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    background-color: #ffffffd7;
                    justify-content: center;
                    .anticon {
                      font-size: 2rem !important;
                    }
                  }
                }
              }
            }
          }

          .error {
            color: red;
            font-size: 1.6rem;
            font-family: $font-primary;
            line-height: normal;
            text-align: center;
            width: 100%;
            margin-top: 1rem;
          }

          h4 {
            font-family: $font-primary;
            font-size: 1.6rem;
            color: $color-black;
            opacity: 0.6;
            margin-top: 0.5rem;
            margin-bottom: 0;
            text-align: center;
          }
          &--uploading-container {
            width: 10rem;
            height: 10rem;
            margin: 0;
            border-radius: 0;
            border: 1px solid rgb(213, 213, 213);
            display: flex;
            align-items: flex-end;
            justify-content: center;
            position: relative;
            margin: 0 auto;
            img {
              position: absolute;
              right: 0;
              top: 0;
              width: 100%;
              height: 100%;
            }
            .ant-spin {
              margin-bottom: 0.5rem;
              .anticon {
                font-size: 16px !important;
              }
            }
          }
          &--textarea {
            margin-top: 2rem;
            p {
              font-size: 1.6rem;
              color: $color-black;
              font-family: $font-primary-medium;
              font-weight: 500;
              margin-bottom: 1rem;
              line-height: 2rem;
              @include respond(tab-port) {
                font-size: 1.8rem;
                line-height: 2.4rem;
              }
            }
            textarea {
              color: $color-black;
              font-size: 1.6rem;
              font-family: $font-secondary;
              line-height: 2.3rem;
              border: 1px solid hsla(0, 0%, 43.9%, 0.2);
              width: 100%;
              padding: 0.8rem 1rem;
              outline: none;
              border-radius: 0.5rem !important;
              background-color: $color-white;
              resize: none;
              height: 6rem;
              &::placeholder {
                color: $color-black;
                opacity: 0.4;
              }
              &::-webkit-scrollbar-track {
                background-color: #03030309;
              }

              &::-webkit-scrollbar {
                width: 0.2rem;
                height: 0.2rem;
                background-color: transparent;
                border-radius: 4rem;
              }

              &::-webkit-scrollbar-thumb {
                background-color: $color-primary;
                border-radius: 4rem;
              }
              @include respond(tab-port) {
                font-size: 1.8rem;
                line-height: 2.4rem;
              }
            }
          }
        }

        &__footer {
          width: 100%;
          margin-top: 1rem;
          padding: 2rem 3rem 3rem;

          .ant-btn {
            width: 10rem;
            background-color: $color-primary;
            color: $color-white;
            font-family: $font-primary-medium;
            font-size: 1.6rem;
            height: 4.2rem;
            border: none;
            margin-left: auto;
            display: block;

            span {
              line-height: normal;
            }
          }
          .disable-btn {
            pointer-events: none;
            opacity: 0.5;
          }
        }
      }
    }
  }
}
.photo-resume-view-modal {
  padding-bottom: 0;
  border-radius: 1rem;
  width: 90rem !important;
  top: 5rem !important;
  .ant-modal-content {
    border-radius: 1rem;
    margin-bottom: 5rem;
    .ant-modal-close {
      margin: 0 !important;
      padding: 1rem;
      background-color: #ffffff;
      border-radius: 0 !important;
      border-start-end-radius: 1rem !important;
    }
  }
  .view-photo {
    width: 100%;
    height: 50rem;
    background-color: $color-black;
    border-start-start-radius: 1rem;
    border-start-end-radius: 1rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .description {
    padding: 1.5rem 2rem;
    p {
      font-size: 1.6rem;
      color: $color-black;
      font-family: $font-primary;
      margin-bottom: 0;
      line-height: 2.5rem;
    }
  }
}
.show-photo-resume-lenght-error-modal {
  width: 45rem !important;
  top: 15rem;
  .ant-modal-content {
    .ant-modal-close {
      margin: 2.5rem 2rem 0 0 !important;
    }
    .ant-modal-body {
      .show-photo-resume-lenght-error-modal-container {
        padding: 5rem 3rem;
        img {
          width: 5rem;
          margin: 0 auto 1.5rem;
          display: block;
        }
        h1 {
          font-size: 1.8rem;
          font-family: $font-primary;
          margin-bottom: 0;
          line-height: 2.2rem;
          color: $color-black;
          text-align: center;
        }
      }
    }
  }
}
</style>
