<template>
  <div class="personal-information">
    <div class="personal-information__header">
      <h4>Personal Information</h4>
    </div>
    <div class="personal-information__body">
      <div class="information">
        <h2>Information</h2>
        <form action="">
          <label class="input-label" for="name">Full Name / Pseudonym</label>
          <input id="name" type="text" v-model="updateUserName" />
          <p v-if="nameFieldError" class="error-msg">This field is required</p>
          <label class="input-label" for="email">Email Address</label>
          <input id="email" type="email" v-model="updateEmail"/>
          <p v-if="emailFieldError" class="error-msg">This field is required</p>
          <label class="input-label" for="email">Recovery Email <span>(optional)</span></label>
          <input id="email" type="email" v-model="recoveryEmail"/>
          <label class="input-label" for="website">Website <span>(optional)</span></label>
          <input id="website" type="text" v-model="updateWebsite" />
          <p v-if="urlError" class="error-msg">{{ urlError }}</p>
          <label class="input-label" for="website">Address <span>(optional)</span></label>
          <a-select
            class="location-select"
            v-model:value="address"
            show-search
            placeholder="Search location"
            :options="searchedLocationResults"
            @focus="handleFocus"
            @blur="handleBlur"
            @change="handleLocation"
            @search="handleSearch"
            :not-found-content="undefined"
          >
          <template v-if="fetching" #notFoundContent>
            <a-skeleton
              active
              class="dropdown-search-skeleton"
              :title="null"
              :paragraph="{ rows: 3, width: ['full'] }"
            />
          </template>
        </a-select>
        </form>
        <p class="error-msg" v-show="onError">{{ fieldErr }}</p>
        <a-button :loading="loading" class="update-profile-btn btn-primary" @click="updateInformation()">Update Profile</a-button>
      </div>
    </div>
  </div>
  <message-toaster
    :visible="showMessageModal"
    :message="message"
    @handle-close="showMessageModal = false"
  >
  </message-toaster>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import MessageToaster from "../../BaseComponents/MessageToaster.vue";

export default {
  components: {
    MessageToaster
  },
  data() {
    return {
      loading: false,
      updateUserName: "",
      onUpdate: false,
      updateEmail: "",
      urlError: '',
      updateWebsite: '',
      nameFieldError: false,
      emailFieldError: false,
      fieldErr: "",
      onError: false,
      recoveryEmail: "",
      birthday: "",
      showMessageModal: false,
      message: {},
      options: [
        { label: 'Option 1', value: 'Option 1' },
        { label: 'Option 2', value: 'Option 2' },
        { label: 'Option 3', value: 'Option 3' }
      ],
      address:'',
      fetching: false,
    };
  },
  created() {
      this.getUserInfo()
  },
  computed:{
    ...mapGetters({
      searchedLocationResults: 'profile/searchedLocationResults'
    })
  },
  watch:{
    value:{
      handler(val){
        console.log(val)
      }
    }
  },
  methods: {
    ...mapActions({
      searchLocation: 'profile/searchLocation',
      emptySearchedLocations: 'profile/emptySearchedLocations'
    }),
    validateUrl(link) {
      if (link === '') {
        this.urlError = '';
      } else {
        const regex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,6}(\.[a-zA-Z]{2})?([/?].*)?$/
        this.urlError = regex.test(link) ? '' : 'Enter a valid URL.';
      }
    },
    async getUserInfo() {
      try {
        const response = await this.$store.dispatch("profile/fetchProfileInfo");
        if (response.status === 200) {
          this.updateUserName = response.data.profile.full_name;
          this.updateEmail = response.data.email;
          this.recoveryEmail = response.data.secondary_email;
          this.updateWebsite = response.data.profile.website_url;
          this.address = response.data.profile.address
        }
      } catch (err) {
        console.log(err);
      }
    },
    async updateInformation() {
      if (this.updateWebsite !== '') {
        this.validateUrl(this.updateWebsite);
      }
      if (this.updateWebsite !== '' && this.urlError) {
        return;
      }
      if (this.updateUserName === '') {
        this.nameFieldError = true;
        this.emailFieldError = false;
        return false;
      }
      if(this.updateEmail == ""){
        this.emailFieldError = true;
        this.nameFieldError = false;
        return false
      }
      this.loading = true;
      let payload = {
        email: this.updateEmail,
        secondary_email: this.recoveryEmail,
        profile: {
          full_name: this.updateUserName,
          website_url: this.updateWebsite,
          address: this.address
        },
      };
      try {
        const response = await this.$store.dispatch(
          "profile/updateProfileInfo",
          payload
        );
        if (response.status === 200) {
          this.onUpdate = true;
          this.onError = false;
          this.updateMsg = "Information has been updated";
          this.showMessageModal = true;
          this.message = {
            title: "Profile updated successfully",
            type: "success",
          };
        }
      } catch (err) {
        this.onError = true;
        this.onUpdate = false;
        this.fieldErr = err.response.data.email[0];
        this.showMessageModal = true;
        this.message = {
          title: "Something went wrong",
          type: "failure",
        };
      }
      this.loading = false;
    },
    async handleSearch(e){
      if (e){
        this.fetching = true
      
      this.debounce(async ()=>{
          const that = this
          await this.searchLocation(e)
          that.fetching = false
      }, 1000)
    }
    },
    async handleLocation(e){
      this.address = e
      this.emptySearchedLocations()
    },
    debounce(method, timer) {
      if (this.$_debounceTimer !== null) {
        clearTimeout(this.$_debounceTimer);
      }
      this.$_debounceTimer = setTimeout(() => {
        method();
      }, timer);
    },
  }
};
</script>

<style lang="scss">
@include reset-css;

.personal-information {
  background-color: $color-white;
  margin-top: 1.5rem;
  &__header {
    border-bottom: 0.24rem solid rgba(119, 119, 119, 0.123);
    padding: 2rem 4rem;
    h4 {
      font-size: 1.8rem;  
      font-family: $font-primary-bold;
      margin-bottom: 0;
    }
    p {
      font-size: 1.6rem;
      font-family: $font-primary;
      padding: 0.5rem 0;
    }
  }
  &__body {
    padding: 3rem 4rem;
    .profile-picture {
      h2 {
        font-size: 1.6rem;
        font-family: $font-primary-bold;
      }
      .picture {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 1.5rem 0.4rem;
        .user-img {
          width: 11.5rem;
          img {
            width: 100%;
          }
        }
        .upload-btn {
          display: flex;
          flex-direction: column;
          margin: 0 2rem;
          .btn {
            background-color: $color-primary;
            color: $color-white;
            height: 4.2rem;
            font-size: 1.6rem;
            font-family: $font-primary;
            border: none;
            margin: 1rem 0;
            cursor: pointer;
          }
          a {
            font-size: 1.6rem;
            font-family: $font-primary;
            opacity: 0.5;
            color: $color-black;
          }
        }
      }
    }
    .information {
      h2 {
        font-size: 1.6rem;
        font-family: $font-primary-bold;
      }
      form {
        margin-top: 2rem;
        .input-label {
          font-size: 1.6rem;
          font-family: $font-primary;
          margin: 1.5rem 0;
          display: block;
          span {
            color: grey;
          }
        }
        input {
          @include respond(laptop-small) {
            width: 100%;
          }
          width: 49rem;
          background-color: $color-light-grey-2;
          font-size: 1.6rem;
          font-family: $font-primary;
          padding: 1rem 2rem;
          outline: none;
          border: 0.22rem solid #70707033;
          margin-bottom: 1rem;
        }
        .ant-calendar-picker {
          width: 49rem;
          background-color: $color-light-grey-2;
          margin-bottom: 1rem;
          @include respond(laptop-small) {
            width: 100%;
          }
          .ant-calendar-picker-input {
            font-size: 1.6rem;
            font-family: $font-primary;
            padding: 1rem 2rem;
            outline: none;
            border: 0.22rem solid #70707033;
            margin-bottom: 0;
            box-shadow: none;
          }
          .anticon {
            right: 2.2rem;
          }
        }
        .ant-select {
          width: 49rem;
          background-color: $color-light-grey-2;
          margin-bottom: 1rem;
          box-shadow: none;
          outline: none;
          border-radius: 0.5rem !important;
          border: 0.22rem solid #70707033;
          position: relative;
          height: 4.8rem;
          @include respond(laptop-small) {
            width: 100%;
          }
          .ant-select-selector {
            font-size: 1.6rem;
            font-family: $font-primary;
            padding: 0 4rem 0 2rem;
            outline: none !important;
            border: none !important;
            margin-bottom: 0;
            background-color: transparent;
            box-shadow: none !important;
            border-radius: 0.5rem !important;
            height: 100% !important;
            .ant-select-selection-search {
              left: 2rem;
              padding-right: 1.5rem;
              .ant-select-selection-search-input {
                height: 100%;
              }
            }
            .ant-select-selection-placeholder {
              padding-right: 0;
              color: $color-black;
              opacity: 0.4;
            }
          }
          .ant-select-arrow {
            right: 2rem;
            top: 2.4rem;
            display: flex;
            align-items: center;
            justify-content: center;
            display: none;
            .anticon {
              font-size: 1.5rem;
              color: $color-black;
              opacity: 0.4;
            }
          }
        }
      }
      .update-msg {
        margin: 0;
        font-size: 1.6rem;
        font-family: $font-primary;
        color: $color-primary;
      }
      .error-msg {
        margin: -0.5rem 0 0;
        font-size: 1.6rem;
        font-family: $font-primary;
        color: red;
      }
    }
  }
}
</style>
