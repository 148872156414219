<template>
  <a-modal
    :visible="subscriptionPackagesModalVisibility"
    :footer="null"
    @cancel="closeModal"
    :maskClosable="false"
    :closable="isSubscriptionExpired"
    class="premium-packages-modal"
  >
  <div class="premium-packages-modal-container">
      <div class="premium-packages-modal-container__header">
        <!-- <div class="premium-packages-modal-container__header--image"> -->
          <!-- <img src="@/assets/icons/expire-date-icon.svg" alt="Expire Icon" /> -->
        <!-- </div> -->
        <div class="premium-packages-modal-container__header--content">
          <h2 v-if="remainingDay">Your trial expires in {{ remainingDay }} {{ remainingDay === 1 ? `day` : `days` }} </h2>
          <h2 v-else>Choose Your Plan</h2>
          <!-- <p>To extend accesDay">s you can purchase a Glocal subscription from our mentioned packages below.</p> -->
        </div>
      </div>
      <div class="premium-packages-modal-container__body">
         <h1 class="premium-packages-modal-container__body--title">Subscribe to maintain access to Glocal the world's first News SuperIntelligence environment.</h1>
         <div class="premium-packages-modal-container__body--cards">
          <package-card
            :class="{ 'select-package-card': isMonthlySelected }"
            :packageName="'Month'"
            :actualPrice="5"
            :discountedPrice="4 + '.99'"
            :duration="30"
            @select-package="selectPackage"
          ></package-card>
          <package-card
            :class="{ 'select-package-card': isYearlySelected }"
            :packageName="'Year'"
            :actualPrice="60"
            :discountedPrice="49 + '.99'"
            :duration="30"
            @select-package="selectPackage"
          ></package-card>
         </div>
        <!-- <a-tabs v-model:activeKey="activeKey">
          <a-tab-pane  key="1">
            <template #tab>
              <span class="tabs-name">Yearly</span>
            </template>
            <div v-if="skeletonLoading">
              <a-skeleton
                class="package-card-skeleton"
                active
                :title="{ width: '100%' }"
              />
            </div>
            <package-card
              v-else
              :packageName="plans[1].name"
              :actualPrice="plans[1].actual_price"
              :discountedPrice="plans[1].discounted_price"
              :duration="plans[1].duration_in_days"
            ></package-card>
          </a-tab-pane>
          <a-tab-pane key="0">
            <template #tab>
              <span class="tabs-name">Monthly</span>
            </template>
            <div v-if="skeletonLoading">
              <a-skeleton
                class="package-card-skeleton"
                active
                :title="{ width: '100%' }"
              />
            </div>
            <package-card
              v-else
              :packageName="plans[0].name"
              :actualPrice="plans[0].actual_price"
              :discountedPrice="plans[0].price"
              :duration="plans[0].duration_in_days"
            ></package-card>
          </a-tab-pane>
        </a-tabs> -->
      </div>
      <div class="premium-packages-modal-container__footer">
        <a-button
          :loading="loader"
          @click="subscribe()"
          class="premium-packages-modal-container__footer--btn"
        >
          Proceed to Payment
        </a-button>
        <a-button
          v-if="isSubscriptionExpired"
          @click="closeModal()"
          class="premium-packages-modal-container__footer--btn premium-packages-modal-container__footer--back-btn"
        >
          Back
        </a-button>
        <!-- <p class="premium-packages-modal-container__footer--message">Having questions? Send us a <span @click="showMessageModal">message.</span></p> -->
      </div>
    </div>
  </a-modal>
</template>

<script>
import PackageCard from "./PackageCard.vue";
import {mapGetters} from 'vuex'
export default {
  components: {
    PackageCard,
  },
  props: {
    visiblePackagesModal: Boolean,
    remainingDay: Number,
    isSubscriptionExpired: Boolean
  },
  // watch: {
  //   visiblePackagesModal(val){
  //     if(val){
  //       this.subscriptionPackages();
  //     }
  //   }
  // },
  computed: {
    ...mapGetters({
      'subscriptionPackagesModalVisibility' : 'auth/getSubscriptionPackagesModalVisibility'
    }),
    // email(){
    //   return this.$store.getters['profile/userInformation'].email;
    // },
    user(){
      return this.$store.getters['profile/userInformation'];
    },
    subscribePlans(){
      return this.$store.getters['auth/getSubscriptionPlans'];
    }
  },
  data(){
    return { 
      activeKey: '0',
      isMonthlySelected: true,
      isYearlySelected: false,
      plans: [],
      skeletonLoading: false,
      loader: false,
    }
  },
  methods: {
    closeModal() {
      this.$emit("handle-close", false);
    },
    selectPackage(val) {
      if (val === 'Month') {
        this.isMonthlySelected = true;
        this.isYearlySelected = false;
        this.activeKey='0'
      }else if(val === 'Year') {
        this.isYearlySelected = true;
        this.isMonthlySelected = false;
        this.activeKey='1'
      }
    },
    async subscriptionPackages() {
      this.skeletonLoading = true;
      const response = await this.$store.dispatch(
          "auth/getSubscriptionPackages"
      );
      this.plans = response.data;
      this.skeletonLoading = false;
    },
    async subscribe(){
      this.loader = true;
      const priceId = this.activeKey === '0' ? this.subscribePlans[0].stripe_plan_id : this.subscribePlans[1].stripe_plan_id;
      const payload = {
        price_id: priceId ,
        email: this.user.email,
      };
      try {
        const response = await this.$store.dispatch("auth/paymentCheckout", payload);
        if (response.status === 200) { 
          window.location.href = response.data.url;
          this.closeModal()
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loader = false;
      }
    },
    showMessageModal() {
      this.emitter.emit('visibleMessageModal', true);
    }
  },
};
</script>

<style lang="scss">
.premium-packages-modal {
  width: 65rem !important;
  top: 8rem !important;
  .ant-modal-content {
    .ant-modal-close {
      margin: 3.8rem 3.2rem 0 0 !important;
    }
    .ant-modal-body {
      .premium-packages-modal-container {
        border-radius: 1rem;
        &__header {
          display: flex;
          align-items: center;
          flex-direction: column;
          height: fit-content;
          padding: 4.3rem 3.7rem 1.6rem;
          border-bottom: 1px solid $color-dark-grey-5;
          &--image {
            width: 8.5rem;
            height: auto;
            margin-bottom: 2rem;
            img {
              width: 100%;
              height: auto;
            }
          }
          &--content {
            h2 {
              font-family: $font-primary-bold;
              font-size: 2.8rem;
              text-align: center;
              line-height: 3.7rem;
              color: $color-black;
              margin-bottom: 2rem;
            }
            p {
              font-family: $font-primary;
              font-size: 1.6rem;
              margin-bottom: 0;
              line-height: 2.2rem;
              color: #696969;
              text-align: center;
              padding: 0 2rem;
            }
          }
        }
        &__body {
          padding: 2rem 2.4rem 0;
          &--title {
            font-family: $font-primary-medium;
            font-size: 2rem;
            text-align: center;
            line-height: 2.7rem;
            color: $color-black;
            margin-bottom: 3rem;
          }
          &--cards {
            display: flex;
            align-items: center;
            @media (max-width: 400px) {
              flex-direction: column;
              .package-card {
                margin-bottom: 2rem;
              }
            }
            .select-package-card {
                border: 2px solid $color-primary;
            }
          }
        }
        &__footer {
          padding: 3.7rem 2.3rem 3rem;
          &--btn {
            background-color: $color-primary;
            font-family: $font-primary-medium;
            font-size: 1.6rem;
            height: 4.8rem;
            width: 100%;
            border: none;
            outline: none;
            color: $color-white;
            cursor: pointer;
            &:hover,
            &:active,
            &:focus {
              background-color: $color-primary;
              color: $color-white;
            }
            span {
              line-height: normal;
            }
          }
          &--back-btn {
            background-color: #ebebeb;
            color: $color-black;
            margin-top: 1.2rem;
            &:hover,
            &:active,
            &:focus {
              background-color: #ebebeb;
              color: $color-black;
            }
          }
          &--message {
            font-family: $font-primary;
            font-size: 1.6rem;
            margin-bottom: 0;
            line-height: 2.2rem;
            color: #696969;
            text-align: center;
            margin-top: 2rem;
            span {
              color: $color-primary;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>
