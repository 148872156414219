<template>
  <div id="active-article-read" class="pdb-article-read-view">
    <div class="pdb-article-read-view__body">
      <a-row :gutter="[0, 18]">
        <a-col  :xs="4" :sm="3" :md="1" :lg="1" :xl="1" class="pdb-article-read-view__body--left">
          <div v-if="searchingArticles">
            <a-skeleton
              class="sidebar-skeleton"
              active
              :title="{ width: '100%' }"
            />
          </div>
          <readview-side-bar
            v-else
            :articleId="id"
            :title="title"
            :image="imageUrl"
            :source="source"
            :url="url"
            :date="date"
            :userReaction="userReaction"
            @scrollToComments="scrollToCommentSection"
          ></readview-side-bar>
        </a-col>
        <a-col :xs="24" :sm="24" :md="23" :lg="23" :xl="23" class="pdb-article-read-view__body--right px-sm-0">
          <a-row :gutter="[18, 18]">
            <a-col :xs="24" :sm="24" :md="16" :lg="18" :xl="18" class="px-sm-0">
              <div v-if="searchingArticles">
                <a-skeleton
                  class="article-view-card-skeleton"
                  active
                  :title="{ width: '100%' }"
                  :paragraph="{
                    rows: 6,
                    width: ['100%', '100%', '100%', '100%', '100%', '50%'],
                  }"
                />
              </div>
              <div v-else class="read-acticle-container">
                <div class="read-acticle-container--header">
                  <div class="steps">
                    <h2>
                      <span class="topic">{{ topic }} ></span>
                      <span class="title">{{ title }}</span>
                    </h2>
                  </div>
                </div>
                <div class="read-acticle-container--body">
                  <div class="article-view-card">
                    <div
                      class="article-view-card__header"
                      :style="backgroundStyle"
                    >
                      
                      <div class="article-view-card__header--content">
                        <div class="article-view-card__header--content--details">
                          <h2 class="article-view-card__header--content--details--title">
                            <a :href="url" target="_blank">{{ title }}</a> 
                          </h2>
                          <div class="article-view-card__header--content--details--auth">
                            <img
                              v-if="sourceLogo"
                              :src="sourceLogo"
                              alt="icon"
                            />
                            <img v-else
                              class="logo-image"
                              src="@/assets/icons/newspaper.svg"/>
                            <p>
                              {{ authorName }},
                              <span class="source"><a :href="url" target="_blank">{{ source }}</a></span> |
                              <span class="date">Publish date: {{ date }}</span>
                            </p>
                          </div>
                        </div>
                        <div class="article-view-card__header--content--buttons">
                          <button
                            class="btn"
                            :class="{ zoomed: isZoomed }"
                            @click="visibleRateArticleModal"
                          >
                            <img src="@/assets/icons/data_exploration.svg" alt="icon" class="icon rate-icon" />
                            <span>Rate this Article</span>
                          </button>
                          <button
                            class="btn"
                            :class="{ zoomed: isZoomed }"
                            @click="scrollToCommentSection()"
                          >
                            <img src="@/assets/icons/mark_chat_unread.svg" alt="icon" class="icon chat-icon" />
                            <span>Comments</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="article-view-card__readview-container">
                      <readview-side-bar
                        :articleId="id"
                        :title="title"
                        :image="imageUrl"
                        :source="source"
                        :url="url"
                        :date="date"
                        :userReaction="userReaction"
                        @scrollToComments="scrollToCommentSection"
                      ></readview-side-bar>
                    </div>
                    <div class="article-view-card__body">
                      <div v-if="htmlContent !== 'false'" class="article-view-card__body--description">
                        <p v-html="htmlContent"></p>
                      </div>
                      <div v-else class="article-view-card__body--description">
                        <p>{{ text }} </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div ref="articleCommentSection" class="read-acticle-container--footer">
                  <a-typography-title :level="3"> {{ articleDiscussionsCount }} Comments</a-typography-title>
                  <article-discussion-section :articleId="articleId"/>
                </div>
              </div>
            </a-col>
            <a-col id="active-insights" :xs="24" :sm="24" :md="8" :lg="6" :xl="6">
              <div class="insights-section">
                <div class="insights-section__header">
                  <h4>Related News</h4>
                </div>
                <div class="insights-section__body">
                  <insight-view :parentLoading="searchingArticles"> </insight-view>
                </div>
              </div>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </div>
    <div class="pdb-article-read-view__footer">
    </div>
    <!-- <discussions-collapse
    :isArticleReadview="true"
    :articleId="this.id"
    >
    </discussions-collapse> -->

    <topic-discussion-drawer
      :drawerVisibility="showCommentDrawer"
      :isArticleView="true"
      @close-drawer="closeCommentDrawer()"
    ></topic-discussion-drawer>
  </div>
  <!-- Rate article modal -->
  <a-modal
    v-model:visible="showRateArticleModal"
    :footer="null"
    wrapClassName="rate-article-modal"
  >
    <article-rating-modal
      :visible="showRateArticleModal"
      :articleId="id"
      @handle-close="closeRateArticleModal"
    >
    </article-rating-modal>
  </a-modal>
  <message-toaster
      :visible="showMessageModal"
      :message="message"
      @handle-close="showMessageModal = false"
    >
  </message-toaster>

</template>

<script>
import ReadviewSideBar from "./ReadviewSideBar.vue";
// import MoreArticlesSection from "../PdbComponents/MoreArticlesSection.vue";
import InsightView from "../PdbComponents/InsightView.vue";
import MessageToaster from '../BaseComponents/MessageToaster.vue';
// import TopicDiscussion from '../BaseComponents/commentsSection/TopicDiscussion.vue';
import { mapActions } from "vuex/dist/vuex.cjs.js";
// import DiscussionsCollapse from "../BaseComponents/commentsSection/DiscussionsCollapse.vue";
import ArticleRatingModal from '../BaseComponents/ArticleRatingModal.vue';
import TopicDiscussionDrawer from '../TopicDiscussion/TopicDiscussionDrawer.vue';
import ArticleDiscussionSection from "../BaseComponents/commentsSection/ArticleDiscussionSection.vue";

export default {
  components: {
    // MoreArticlesSection,
    ReadviewSideBar,
    InsightView,
    MessageToaster,
    // TopicDiscussion,
    ArticleRatingModal,
    // DiscussionsCollapse,
    TopicDiscussionDrawer,
    ArticleDiscussionSection
  },
  data() {
    return {
      title: "",
      authorName: "",
      text: "",
      imageUrl: "",
      sourceLogo: "",
      source: "",
      url: "",
      id: "",
      date: "",
      searchingArticles: false,
      userReaction: {},
      showMessageModal: false,
      message: {},
      htmlContent: "",
      activeNewsTab: true,
      showRateArticleModal: false,
      isZoomed: false,
      zoomInterval: null,
      showCommentDrawer: false,
    }
  },
  computed: {
    topic(){
      return this.$store.getters['discover/getTopicRoute'] ? this.$store.getters['discover/getTopicRoute'].replace(/-/g, ' ') : "";
    },
    articleId(){
      return this.$route.params?.articleId
    },
    articleDiscussionsCount() {
      let count = this.$store.getters["discover/getDiscoverArticleComments"].length
      this.$store.getters["discover/getDiscoverArticleComments"].forEach(parentComment=>{
        count += parentComment.child.length
      })
      return count
    },
    backgroundStyle() {
      return {
        backgroundImage: `linear-gradient(179.9deg, rgba(0, 0, 0, 0) 0.09%, #000000 90.3%), url('${this.imageUrl}')`,
      };
    },
  },
  async created(){
    // until lazy loading of images is not being implemented do not remove this.....
    this.searchingArticles = true
  },
  mounted(){
    window.scrollTo(0,0);
    this.emitter.on('show-submit-rating-msg', (message)=>{
      this.$showToast({'message':message.title, 'type':message.type})
    });
    window.addEventListener('scroll', (val)=> {
      const section = document.getElementById(`active-insights`);
      if(section.offsetTop > scrollY){
        this.activeNewsTab = true;
      }
      else if(section.offsetTop < scrollY){
        this.activeNewsTab = false;
      }

    });
    this.startZoomEffect();
  },
  beforeUnmount() {
    this.stopZoomEffect();
  },
  unmounted(){
    this.setSelectedDiscussionArticleId(null)
  },
  watch: {
    $route: {
      async handler(to) {
          try{
            if (to.params.articleId) {
              await this.getArticle(to.params.articleId);
              this.$store.commit('discover/SET_TOPIC_ROUTE', to.params.topic);
            }
          }
          catch(err){
            console.log(err)
          }
          finally{
            this.searchingArticles = false
          }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      setSelectedDiscussionArticleId:'discussion/setSelectedDiscussionArticleId',
      toggleSearchInsightsArticleLoader: 'discover/toggleSearchInsightsArticleLoader'
    }),
    async getArticle(id) {
      // this.searchingArticles = true;
      const response = await this.$store.dispatch(
        "article/getNotificationsArticleData",
        id
      );
      if (response.status === 200) {
          this.title = response.data.title,
          this.authorName = response.data.authors,
          this.text = response.data.description,
          this.imageUrl = response.data.image,
          this.sourceLogo = response.data.logo,
          this.source = response.data.article_url.source,
          this.url = response.data.article_url.url,
          this.id = response.data.id,
          this.date = response.data.pub_date,
          this.userReaction = response.data.user_reaction,
          // this.searchingArticles = false;
          this.htmlContent = response.data.html;
      }
      this.$store.dispatch("discussion/toggleDiscoverTopicCommentLoader");
      await this.$store.dispatch("discover/fetchDiscoverArticleComments",{'article_id':this.id});
      this.setSelectedDiscussionArticleId(this.id)
      await this.$store.dispatch("discussion/toggleDiscoverTopicCommentLoader");
    },
    visibleRateArticleModal() {
      this.showRateArticleModal = true;
    },
    closeRateArticleModal() {
      this.showRateArticleModal = false;
      this.showRateArticleDrawer = false
    },
    startZoomEffect() {
      this.zoomInterval = setInterval(() => {
        this.isZoomed = !this.isZoomed;
      }, 3000); // Toggle zoom class every 3 seconds
    },
    stopZoomEffect() {
      clearInterval(this.zoomInterval);
    },
    scrollToCommentSection() {
      const targetElement = this.$refs.articleCommentSection
      if (targetElement){
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    },
    closeCommentDrawer() {
      this.showCommentDrawer = false;
    },
  },
};
</script>

<style lang="scss">
.pdb-article-read-view {
  height: fit-content;
  position: relative;
  // padding-bottom: 8rem;
  &__body {
    padding: 0 2rem;
    position: relative;
    @include respond(tab-port) {
      padding: 0 1rem;
      .px-sm-0 {
        padding: 0 !important;
      }
    }
    &--left {
      display: block !important;
      @include respond(tab-port) {
        display: none !important;
      }
    }
    .read-acticle-container {
      position: relative;
      height: 100%;
      margin-left: 1.9rem;
      @include respond(tab-port) {
        margin-left: 0;
      }
      &--header {
        position: sticky;
        top: 0;
        padding: 3.3rem 0 2.8rem;
        z-index: 2;
        background-color: $color-white;
        margin: 0 2rem;
        @include respond(tab-port) {
          display: none;
          margin: 0;
        }
        .steps {
          display: flex;
          align-items: center;
          h2 {
            font-size: 1.8rem;
            font-family: $font-primary-medium;
            margin-bottom: 0;
            line-height: 2rem;
            display: flex;
            align-items: center;
            width: 100%;
            color: $color-black;
            @include respond(laptop-large) {
              font-size: 1.6rem;
            }
            @include respond(phone-x-small) {
              font-size: 1.2rem;
            }
            .title {
              margin: 0 0.4rem;
              text-overflow: ellipsis;
              max-width: 20rem;
              height: 2rem;
              overflow: hidden;
              white-space: nowrap;
            }
          }
        }
      }
      &--body {
        .article-view-card {
          margin: 0 0 2rem;
          &__header {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-end;
            border-top-left-radius: 1rem;
            border-top-right-radius: 1rem;
            height: 100%;
            max-height: 40rem;
            min-height: 40rem;
            border-radius: 1rem !important;
            background-repeat: no-repeat;
            background-size: cover;
            background-position-x: inherit;
            background-position-y: inherit;
            margin: 0 2rem;
            @include respond(tab-port) {
              margin: 0;
            }
            &--content {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 1.8rem 2.3rem;
              width: 100%;
              &--details {
                padding-right: 3rem;
                &--title {
                  font-size: 2.4rem;
                  font-family: $font-primary-bold;
                  line-height: 3.3rem;
                  color: $color-white;
                  font-weight: 700;
                  margin-bottom: 1rem;
                  padding: 0;
                  overflow: hidden;
                  display: -webkit-box;
                  -webkit-line-clamp: 2; /* number of lines to show */
                  line-clamp: 2;
                  -webkit-box-orient: vertical;
                  a {
                    color: $color-white;
                    &:hover {
                      color: $color-white;
                      text-decoration: underline;
                    }
                  }
                }
                &--auth {
                  display: flex;
                  align-items: center;
                  img {
                    width: 3.7rem;
                    height: 3.7rem;
                    border-radius: 100%;
                    margin-right: 1rem;
                    background-color: #d9d9d9;
                  }
                  .logo-image {
                    object-fit: contain;
                    border-radius: 0;
                    background-color: transparent;
                    width: 2rem;
                    height: 2rem;
                  }
                  p {
                    font-size: 1.2rem;
                    font-family: $font-primary;
                    line-height: 1.6rem;
                    color: $color-white;
                    margin-bottom: 0;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2; /* number of lines to show */
                    line-clamp: 2;
                    -webkit-box-orient: vertical;
                    .source {
                      text-transform: uppercase;
                      a {
                        color: $color-white;
                        &:hover {
                          color: $color-white;
                          text-decoration: underline;
                        }
                      }
                    }
                    .date {
                      color: #b6b6b6;
                    }
                  }
                }
              }
              &--buttons {
                display: flex;
                flex-direction: column;
                width: fit-content;
                gap: 1rem;
                .btn {
                  display: flex;
                  align-items: center;
                  background-color: $color-white;
                  color: $color-black;
                  transition: border-color 0.3s;
                  border: 0.2rem solid $color-white;
                  outline: none;
                  border-radius: 1rem !important;
                  font-family: $font-primary-medium;
                  padding: 1rem 2rem;
                  cursor: pointer;
                  .icon {
                    width: 2rem;
                    height: auto;
                    margin-right: 1rem;
                  }
                  .chat-icon {
                    width: 1.6rem;
                  }
                  span {
                    display: block;
                    font-size: 1.6rem;
                    line-height: 2.2rem;
                    width: max-content;
                  }
                  &.zoomed {
                    animation: zoomInOut 3s infinite;
                    border-color: $color-primary;
                  }
                }
                @keyframes zoomInOut {
                  0% {
                    transform: scale(1);
                  }
                  50% {
                    transform: scale(1.1);
                  }
                  100% {
                    transform: scale(1);
                  }
                }
                @include respond(tab-port) {
                  .btn {
                    display: none;
                  }
                }
              }
            }
          }
          &__readview-container {
            display: none;
            @include respond(tab-port) {
              display: block;
              position: sticky;
              top: 8rem;
            }
            @include respond(phone-x-small) {
              position: sticky;
              top: 10.8rem;
            }
            .read-view-sidebar {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 2.2rem 2.8rem;
              border-bottom: 1px solid #0000004D;
              &__button {
                margin-bottom: 0;
                &--btn {
                  width: 6rem;
                  height: 6rem;
                  margin-bottom: 0.7rem;
                  img {
                    width: 2rem;
                  }
                }
                &--title {
                  font-size: 1.8rem;
                }
                .the-verticle-reactions-bar {
                  &__reaction-status {
                    &--reactions-container {
                      .btn {
                        width: 6rem;
                        height: 6rem;
                        margin-bottom: 0.7rem;
                        img {
                          width: 2rem;
                        }
                      }
                      .title {
                        font-size: 1.8rem;
                      }
                    }
                  }
                }
              }
            }
          }
          &__body {
            &--description {
              padding: 0 4rem;
              @include respond(tab-port) {
                padding: 2.2rem 4.4rem;
                background-color: #f3f3f3;
              }
              p {
                font-size: 1.8rem;
                font-family: $font-primary;
                margin-bottom: 0;
                line-height: 2.7rem;
                margin: 2rem 0;
                text-align:justify;
                color: $color-black;
              }
              h1, h2, h3, h4, h5, h6 {
                font-family: $font-primary-medium;
                color: $color-black;
              }
            }
          }
        }
      }
      &--footer{
        padding: 0 4rem 2rem;
        .ant-typography {
          margin-bottom: 1.5rem;
          font-size: 1.8rem;
          font-family: $font-primary;
          line-height: 2.7rem;
          color: $color-black;
        }
      }
    }
    .article-view-card-skeleton {
      margin: 0 9px;
      .ant-skeleton-content {
        .ant-skeleton-title {
          height: 40rem;
          border-radius: 1rem;
        }
        .ant-skeleton-paragraph {
          li {
            height: 2rem;
            margin: 2rem 0;
          }
        }
      }
    }
    .insights-section {
      height: 100%;
      &__header {
        padding-top: 3rem;
        position: sticky;
        background-color: white;
        z-index: 22;
        top: 0;
        padding-bottom: 2rem;
        margin-bottom: 0;
        @include respond(tab-port) {
          padding: 2.2rem 4rem;
        }
        h4 {
          position: relative;
          font-size: 2.4rem;
          font-family: $font-primary-medium;
          font-weight: 700;
          margin-bottom: 0;
          line-height: normal;
          &::after {
            content: "";
            width: 4rem;
            height: 0.2rem;
            background-color: #0033dd;
            position: absolute;
            bottom: -0.7rem;
            left: 0;
          }
        }
      }
      &__body {
        max-height: 160rem;
        overflow-y: auto;
        overflow-x: hidden;
        padding-right: 0.4rem;
        @include respond(tab-port) {
          padding: 2.2rem 0;
          margin: 0 4rem 2rem;
          max-height: fit-content;
          overflow-x: scroll;
          .insight-view > div > div {
            display: flex;
            flex-flow: nowrap;
            > div {
              flex: 0 0 45%;
              max-width: 45%;
              .accuracy-credibility-container {
                padding: 0 1rem;
              }
              @include respond(phone-x-small) {
                flex: 0 0 80%;
                max-width: 80%;
                .accuracy-credibility-container {
                  padding: 0;
                }
              }
            }
          }
        }
        &::-webkit-scrollbar {
          width: 0.4rem;
          height: 0.4rem;
        }

        &::-webkit-scrollbar-track {
          background-color: transparent;
          border-radius: 0.8rem;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $color-primary;
          border-radius: 0.8rem;
        }
      }
    }
    .sidebar-skeleton {
      .ant-skeleton-content {
        .ant-skeleton-title {
          height: 44rem;
          border-radius: 1rem;
        }
        .ant-skeleton-paragraph {
          display: none;
        }
      }
    }
  }
  &__footer {
  }
  .discussion-collapse-container {
    .ant-collapse {
      .ant-collapse-item {
        .ant-collapse-content {
          .ant-collapse-content-box {
            padding-top: 0;
            .comments-section {
              border-top: none !important;
            }
          }
        }
      }
    }
  }
}
.rate-article-modal {
  .ant-modal {
    width: 55rem !important;
    top: 8rem;
    .ant-modal-content {
      background-color: rgb(246, 248, 255);
      .ant-modal-close {
        margin: 2.5rem 1.8rem 0 0;
      }
    }
  }
}
</style>
