<template>
  <a-modal
    width="45rem"
    class="otp-verification-modal"
    :visible="visible"
    :closable="isAddAccount"
    :maskClosable="isAddAccount"
    @cancel="closeModal"
    :footer="null"
  >
    <div class="otp-verification-modal-container">
      <div class="otp-verification-modal-container__logo">
        <img
          class="logo"
          src="../../assets/images/Amblem2@2x.webp"
          alt="Glocal Logo"
        />
      </div>
      <div class="otp-verification-modal-container__content">
        <h1 class="otp-verification-modal-container__content--title">
          Verify your code
        </h1>
        <p class="otp-verification-modal-container__content--para">
          We have sent a code to your email <br />
          is <span>{{ userAbstractEmail }}</span>
        </p>
      </div>
      <div class="otp-verification-modal-container__otp">
        <div class="otp-verification-modal-container__otp--otp-input-group">
          <input
            v-for="(digit, index) in otp"
            :key="index"
            type="text"
            maxlength="1"
            class="otp-verification-modal-container__otp--otp-input-group--otp-input"
            v-model="otp[index]"
            @input="handleInput($event, index)"
            @keydown="handleBackspace($event, index)"
            :ref="'otp-' + index"
          />
        </div>
        <p v-if="error" class="otp-verification-modal-container__otp--message red">{{ error }}</p>
        <p v-if="resentMessage" class="otp-verification-modal-container__otp--message green">{{ resentMessage }}</p>
        <a-button
          class="otp-verification-modal-container__otp--btn"
          :loading="loading"
          :disabled="loading || otp.includes('')"
          @click="submitOtp"
        >
          {{ loading ? "Verifying..." : "Verify" }}
        </a-button>
        <div class="otp-verification-modal-container__resend-btn">
          <p>Didn't recieve code?</p><button @click="resendOTP()">Resend</button>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  props:{
    isAddAccount:{
      type:Boolean,
      default: () => (false)
    },
    isClosable:{
      type: Boolean,
      default: () => (false)
    },
    visible:{
      type: Boolean,
      default: () => (false)
    }
  },
  data() {
    return {
      email: "⋆⋆⋆⋆@gmail.com", // Example email
      otp: Array(6).fill(""), // Six input fields for OTP
      error: "",
      loading: false,
      resentMessage: ""
    };
  },
  computed:{
    ...mapGetters({
      signupUserEmail: 'auth/signupUserEmail',
      userInformation: 'profile/userInformation'
    }),
    userAbstractEmail(){
      const email = this.signupUserEmail ? this.signupUserEmail.split("@")[0] :  ''
      const lastString = this.signupUserEmail ? email.length > 3 ?  email.substring(email.length-2, email.length) : '' : ''
      return this.signupUserEmail ? `...${lastString}@${this.signupUserEmail.split('@')[1]}` : ''
    }
  },
  methods: {
    ...mapActions({
      verifyOTP: 'auth/verifyOTP',
      sendOTP: 'auth/sendOTP',
      addLinkedAccount: 'auth/addLinkedAccount'

    }),
    handleInput(event, index) {
      const value = event.target.value;
      if (value && !isNaN(value)) {
        this.otp[index] = value;
        if (index < this.otp.length - 1) {
          // Move focus to the next input
          this.$refs[`otp-${index + 1}`]?.focus();
        }
      } else {
        this.otp[index] = "";
      }
    },
    handleBackspace(event, index) {
      if (event.key === "Backspace" && !this.otp[index]) {
        if (index > 0) {
          this.$refs[`otp-${index - 1}`]?.focus();
          this.otp[index - 1] = ""; // Clear the previous input
        }
      }
    },
    closeModal() {
      this.email = "";
      this.otp = Array(6).fill("");
      this.error = "";
      this.loading = false;
      this.resentMessage = "";
      this.$emit('close');
    },
    async submitOtp() {
      if (this.otp.includes("")) {
        this.error = "Please complete the OTP fields.";
        return;
      }
      this.error = "";

      try{
        this.loading = true;
        await this.linkAccount()
        await this.verifyOTP({email: this.signupUserEmail, otp:this.otp.join("")})
        this.closeModal()
        if (this.$route.fullPath === '/discover' && this.isAddAccount){     
              window.location.reload()
        }
        else{
          this.$router.push({ path: '/discover'});
        }
      }
      catch(err){
        console.log(err)
        this.error = 'Please enter a valid OTP'
        this.resentMessage = ""
      }
      finally{
        this.loading = false
      }

      // Simulate an API call
      // setTimeout(() => {
      //   this.loading = false;
      //   if (this.otp.join("") === "123456") {
      //     alert("OTP Verified!");
      //     this.closeModal();
      //   } else {
      //     this.error = "Invalid OTP. Please try again.";
      //   }
      // }, 1500);
    },
    async resendOTP(){
      try{
        this.error = ''
        this.resentMessage = "Verification code was resent successfully."
        await this.sendOTP(this.signupUserEmail)
      }
      catch(err){
        console.log(err)
      }
    },
    async linkAccount(){
      const linkedAccount = {
        name: this.userInformation?.profile?.full_name,
        email: this.userInformation?.email,
        profile_picture: this.userInformation?.profile?.profile_picture,
        id: this.userInformation?.id,
        isTopicCollaborationCollapsed: true,
        isWorkspaceTopicCollaborationCollapsed: true
      }
      localStorage.setItem('isTopicCollaborationCollapsed', true)
      localStorage.setItem('isWorkspaceTopicCollaborationCollapsed', true)
      await this.addLinkedAccount(linkedAccount)
    }
  },
};
</script>


<style lang="scss">
.otp-verification-modal {
  .ant-modal-content {
    .ant-modal-close {
      margin: 2.7rem 2.5rem 0 0 !important;
    }
    .ant-modal-body {
      padding: 0;

      .otp-verification-modal-container {
        padding: 3.3rem 2.5rem;
        &__logo {
          width: 3.2rem;
          display: block;
          margin: 0 auto 1.5rem;

          img {
            width: 100%;
            height: auto;
          }
        }

        &__content {
          &--title {
            text-align: center;
            font-size: 2.6rem;
            line-height: 3.5rem;
            font-family: $font-primary-bold;
            color: $color-black;
            margin-bottom: 0.5rem;
          }

          &--para {
            text-align: center;
            margin-bottom: 2.2rem;
            font-size: 1.6rem;
            font-family: $font-primary;
            opacity: 0.7;
            color: $color-black;
            span {
              font-family: $font-primary-medium;
            }
          }
        }

        &__otp {
          text-align: center;

          &--otp-input-group {
            display: flex;
            justify-content: center;
            gap: 1rem;
            margin: 3rem 0 4rem;

            &--otp-input {
              width: 4.5rem;
              height: 5rem;
              font-size: 1.8rem;
              text-align: center;
              border: 1px solid $color-dark-grey-5;
              border-radius: 0.3rem;
              outline: none;
              font-size: 1.6rem;
              color: $color-black;
              &:focus-visible {
                border-color: $color-primary;
              }
            }
          }

          &--message {
            font-size: 1.4rem;
            margin-bottom: 1rem;
            margin-top: -1rem;
          }
          .red {
            color: red;
          }
          .green {
            color: #469f13;
          }

          &--btn {
            background-color: $color-primary;
            font-family: $font-primary-medium;
            font-size: 1.6rem;
            height: 4.8rem;
            width: 100%;
            border: none ;
            outline: none;
            color: $color-white;
            cursor: pointer;
            padding: 0 !important;
            &:hover,
            &:active,
            &:focus {
              background-color: $color-primary;
              color: $color-white;
            }
            span {
              line-height: normal;
            }
            .anticon {
              .anticon-spin {
                font-size: 2.3rem;
              }
            }

            &:disabled {
              opacity: 0.7;
              cursor: not-allowed;
            }
          }
        }

        &__resend-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 2rem;
          p {
            font-size: 1.6rem;
            font-family: $font-primary;
            margin-right: 0.5rem;
            margin-bottom: 0;
            opacity: 0.7;
            color: $color-black;
          }
          button {
            color: $color-primary;
            line-height: normal;
            padding: 0;
            outline: none;
            border: none;
            background-color: transparent;
            font-size: 1.6rem;
            font-family: $font-primary-medium;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
