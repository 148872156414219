<template>
  <section id="for-active-navbar" class="strategic-action-section">
    <TheVisionariesSection />
    <div class="strategic-action-section__header">
      <p>Strategic Approach</p>
      <h2>
        How we take you from data to Strategic action
      </h2>
    </div>
    <div class="strategic-action-section__body">
      <div class="strategic-action-section__body--image-section">
        <img
          src="@/assets/images/strategic-action/strategic-action-section-arrow-top.png"
          alt="strategic actions arrow top"
          class="arrow"
          height="auto"
          width="auto"
        />
        <div class="middle-image">
          <img
            src="@/assets/images/strategic-action/strategic-action-section-image.png"
            alt="strategic actions image"
            height="auto"
            width="100"
          />
        </div>
        <img
          src="@/assets/images/strategic-action/strategic-action-section-arrow-bottom.png"
          alt="arrow"
          class="arrow"
          height="auto"
          width="auto"
        />
      </div>
      <div class="strategic-action-section__body--steps-container">
        <a-row :gutter="[20]">
          <a-col :xs="24" :sm="24" :md="18" :lg="18" :xl="18">
            <a-row :gutter="[20]" class="strategic-action-section__body--steps-container--mobile-arrow-container">
              <img
                src="@/assets/images/strategic-action/strategic-action-section-arrow-top-mobile.png"
                alt="arrow"
                class="arrow arrow-left"
              />
              <a-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
                <div class="step-card">
                  <p class="step-card__sub-title">
                    <img
                      src="@/assets/images/strategic-action/strategic-action-section-arrow-small-top.png"
                      alt="strategic actions image"
                    />
                    <span>Phase 1</span>
                  </p>
                  <h1 class="step-card__title">DATA COLLECTION</h1>
                  <ul class="step-card__list">
                    <li>Mainstream & Alternative News</li>
                    <li>Scholarly Articles & Think Tank Publications</li>
                    <li>Business & Government Publications</li>
                    <li>Tailored Access To Your Specific Sources</li>
                  </ul>
                </div>
              </a-col>
              <a-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
                <div class="step-card">
                  <p class="step-card__sub-title">Phase 2</p>
                  <h1 class="step-card__title">INTEGRATION</h1>
                  <ul class="step-card__list">
                    <li>
                      Context Generated By Cleaned, Structured, & Secured Data
                    </li>
                  </ul>
                </div>
              </a-col>
              <a-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
                <div class="step-card">
                  <p class="step-card__sub-title">Phase 3</p>
                  <h1 class="step-card__title">ANALYSIS</h1>
                  <ul class="step-card__list">
                    <li>
                      Analysis Is Powered By AI & The Collective Intelligence Of
                      Professionals Worldwide
                    </li>
                  </ul>
                </div>
              </a-col>
              <img
                src="@/assets/images/strategic-action/strategic-action-section-arrow-bottom-mobile.png"
                alt=""
                class="arrow arrow-right"
              />
            </a-row>
          </a-col>
          <a-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
            <div class="step-card">
              <p class="step-card__sub-title">Phase 4</p>
              <h1 class="step-card__title">STRATEGIC ACTION</h1>
              <ul class="step-card__list step-card__last-list">
                <li>
                  Strategic & Actionable Insights For Professionals & Leaders In
                  The Private & Public Sectors
                </li>
              </ul>
            </div>
          </a-col>
        </a-row>
      </div>
      <img
        src="@/assets/images/strategic-action/strategic-action-section-bottom-arrow.png"
        alt="Arrow Down"
        class="strategic-action-section__body--bottom-arrow"
        width="auto"
        height="auto"
      />
    </div>
  </section>
</template>

<script>
import TheVisionariesSection from "./TheVisionariesSection.vue"
export default {
  components: {
    TheVisionariesSection,
  }
}
</script>

<style lang="scss">
.strategic-action-section {
  background-color: $color-black;
  padding: 8.9rem 13.2rem 13.9rem;
  @include respond(laptop-large) {
    padding: 8.9rem 5rem 13.9rem;
  }
  @include respond(tab-port) {
    padding: 5rem 4rem 10rem;
  }
  &__header {
    margin-bottom: 26rem;
    margin-top: 14.4rem;
    @include respond(laptop-large) {
      padding: 0 2.1rem;
    }
    @include respond(laptop-large) {
      margin-bottom: 24rem;
    }
    @include respond(tab-port) {
      margin-bottom: 36rem;
    }
    p {
      font-family: $font-primary-medium;
      color: $color-primary;
      font-weight: 700;
      line-height: 3.1rem;
      font-size: 2.4rem;
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 1.5rem;
      @include respond(phone-x-small) {
        font-size: 3rem;
        line-height: 4rem;
      }
    }
    h2 {
      font-family: $font-primary-bold;
      color: $color-white;
      font-weight: 700;
      line-height: 8.2rem;
      font-size: 6rem;
      text-transform: uppercase;
      max-width: 123rem;
      text-align: center;
      margin: 0 auto;
      @include respond(laptop-small) {
        font-size: 4.4rem;
        line-height: 5rem;
        max-width: 100%;
      }
      @include respond(tab-port) {
        font-size: 4rem;
        line-height: 5rem;
        max-width: 100%;
      }
    }
  }
  &__body {
    position: relative;
    @include respond(laptop-large) {
      padding: 0 2.1rem;
    }
    @include respond(tab-port) {
      padding: 0;
    }
    &--image-section {
      display: flex;
      align-items: center;
      width: auto;
      padding: 0 2rem;
      padding-right: 0;
      @include respond(tab-port) {
        padding: 0;
      }
      .middle-image {
        width: 100%;
        margin: 0 2rem;
        margin-top: -189px;
        height: auto;
        @include respond(tab-port) {
          margin: 0;
          margin-top: -34rem;
        }
        img {
          width: 100%;
          height: auto;
        }
      }
      img {
        width: auto;
        height: auto;
      }
      @include respond(tab-port) {
        .arrow {
          display: none;
        }
      }
    }
    &--steps-container {
      &--mobile-arrow-container {
        position: relative;
        display: flex;
        .arrow {
          position: absolute;
          top: 0;
          display: none;
          @include respond(tab-port) {
            display: block;
          }
        }
        .arrow-right {
          height: calc(100% + 11.5rem);
          top: -9rem;
          right: 2rem;
        }
        .arrow-left {
          height: calc(100% + 1rem);
          left: 12px;
          top: 0.5rem;
        }
      }
    }
    &--bottom-arrow {
      padding-left: 2rem;
      width: 99%;
      margin-top: -8rem;
      height: 100%;
      @include respond(tab-port) {
        display: none;
      }
    }
    .step-card {
      padding: 1.5rem 0;
      position: relative;
      height: 100%;
      @include respond(tab-port) {
        padding: 0 4rem;
      }
      &__sub-title {
        font-size: 1.8rem;
        font-family: $font-primary;
        margin-bottom: 1.6rem;
        color: #7b7b7b;
        margin-bottom: 1rem;
        @include respond(tab-port) {
          display: flex;
          align-items: center;
          flex-direction: column;
          width: fit-content;
          position: relative;
          img {
            position: absolute;
            top: -7rem;
            display: block !important;
          }
        }
        img {
          display: none;
        }
        @media (max-width: 768px) {
          font-size: 2.5rem;
          line-height: 3rem;
        }
      }
      &__title {
        font-size: 2.4rem;
        font-family: $font-primary-medium;
        margin-bottom: 1.6rem;
        color: $color-white;
        @include respond(tab-port) {
          font-size: 3rem;
          line-height: 4rem;
        }
      }
      &__list {
        padding-left: 3rem;
        li {
          font-size: 1.8rem;
          font-family: $font-primary-regular;
          margin-bottom: 1.6rem;
          color: $color-white;
          line-height: 2.2rem;
          max-width: 80%;
          list-style: disc;
          @media (max-width: 768px) {
            font-size: 2.5rem;
            line-height: 3rem;
          }
        }
      }
      &__last-list {
        li {
          max-width: 100%;
        }
      }
    }
  }
}
</style>
