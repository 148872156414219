<template>
    <div class="all-news-sources-dropdown">
      <TagifyContacts
        placeholder="Type source"
        :options="filteredSources"
        @fetchSelection="fetchSelectedTopics"
        :isSubmitted="emptySources"
        :prevTags="preSelectedSources"
        :id="'selectSources-'+module"
      />
      <!-- <input
        type="text"
        placeholder="Search for a topic of your choice."
        @focus="visibleSourcesDropdown()"
        v-model="searchSource"
        @input="filterSources"
        class="all-news-sources-dropdown__input"
      />
      <div v-if="openSources" class="all-news-sources-dropdown__dropdown">
        <p v-if="filteredSources.length === 0" class="message">No sources found.</p>
        <p
          v-for="source in filteredSources"
          :key="source"
          @click="selectSource(source.key, source.doc_count)"
        >
          {{ source.key }}
        </p>
      </div>
      <div class="all-news-sources-dropdown__selected-sources">
        <p class="all-news-sources-dropdown__selected-sources--title">Selected Sources:</p>
        <div class="all-news-sources-dropdown__selected-sources--select-container">
          <p class="message" v-if="getSelectedSpecificSources.length === 0">No selected source</p>
          <button
            class="select"
            v-for="(source, index) in getSelectedSpecificSources"
            :key="index"
          >
            <p>{{ source.key }}</p>
            <i @click.stop="removeSelectedIncludeSource(index)" class="icon icon-add_plus"></i>
          </button>
        </div>
      </div> -->
    </div>
  </template>
  
  <script>
  import TagifyContacts from '../../PdbComponents/TagifyContacts.vue';
  export default {
    components: {
      TagifyContacts
    },
    props: {
      emptySources : Boolean,
      module:{
        type: String,
        default: 'add-topic'
      }
    },
    mounted() {
      document.addEventListener('click', this.handleClickOutside);
        this.filteredSources = this.allNewsSources.map(source => { 
          return {
              label: source.source_name,
              value: source.link
          }
        })
        this.preSelectedSources = this.module === 'topic-management' ? this.$store.getters['discover/getTopicDetails']?.interest?.sources_array.map(source=>(source?.key)) :[]
    },
    computed: {
      allNewsSources() {
        return this.$store.getters["discover/getSources"];
      },
      getSelectedSpecificSources(){
        return this.$store.getters['discover/getSelectedSpecificSources'];
      },
    },
    watch:{
      allNewsSources(val){
        this.filteredSources = val
      },
    },
    data() {
      return {
        openSources: false,
        searchSource: "",
        filteredSources: [],
        preSelectedSources: []
      };
    },
    methods: {
      handleClickOutside(event) {
        const element = this.$el; 
        if (!element.contains(event.target)) {
          this.openSources = false; 
          }
      },
      visibleSourcesDropdown() {
        setTimeout(() => {
          this.openSources = true;
        }, 200);
      },
      async selectSource(source, docCount) {
        this.openSources = false;
        const sourceIndex = this.allNewsSources.findIndex(
          (s) => s.key === source
        );
        if (sourceIndex !== -1) {
          this.allNewsSources.splice(sourceIndex, 1);
          const newItem = {
            key: source,
            doc_count: docCount,
          };
          await this.$store.commit("discover/SET_ALL_SOURCES", [...this.allNewsSources]);

          console.log("NEW ITEMS", newItem)
          this.$store.commit("discover/SET_SPECIFIC_NEWS_SOURCES",  newItem);
          this.filterSources();
        }
      },
      removeSelectedIncludeSource(index) {
        const removedSource = this.getSelectedSpecificSources[index];
        this.getSelectedSpecificSources.splice(index, 1);
        this.$store.commit("discover/SET_ALL_SOURCES", [...this.allNewsSources, removedSource]);
      },
      filterSources(){
        if(this.searchSource === ""){
          this.filteredSources = [...this.allNewsSources]
        }
        this.filteredSources = this.allNewsSources.filter((source) =>
          source.key.toLowerCase().startsWith(this.searchSource.toLowerCase())
        );
      },
       debounce(method, timer) {
        if (this.$_debounceTimer !== null) {
          clearTimeout(this.$_debounceTimer);
        }
        this.$_debounceTimer = setTimeout(() => {
          method();
        }, timer);
      },
      fetchSelectedTopics(val) {
        this.debounce(async () => {
            const selectedSource = val.map(source => { 
                return {
                  key: source.label,
                  doc_count: source.value
                }
            })

            console.log("NEW ITEMS 2", selectedSource)
              this.$store.commit('discover/SET_SPECIFIC_NEWS_SOURCES', selectedSource);

        }, 600);
      },
    },
  };
  </script>
  
  <style lang="scss">
  .all-news-sources-dropdown {
    position: relative;
    &__input {
      border-radius: 5px !important;
      font-size: 1.4rem;
      border: 1px solid #c5c6cc;
      font-family: $font-primary;
      width: 100%;
      height: 4rem;
      padding: 1rem;
      outline: none;
      &::placeholder {
        opacity: 0.4;
      }
    }
    &__dropdown {
      position: absolute;
      width: 100%;
      background-color: $color-white;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      border-end-end-radius: 0.5rem;
      border-end-start-radius: 0.5rem;
      max-height: 12rem;
      overflow-y: auto;
      z-index: 1;
      .message{
        cursor: default;
      }
      p {
        margin-bottom: 0;
        padding: 1rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 1.6rem;
        font-family: $font-primary-medium;
        line-height: normal;
        border-bottom: 1px solid $color-dark-grey-5;
        cursor: pointer;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1; /* number of lines to show */
        line-clamp: 1;
        -webkit-box-orient: vertical;
        &:last-child {
          border-bottom: none;
        }
      }
    }
    &__dropdown::-webkit-scrollbar-track {
      background-color: #ffffff3f;
    }
    &__dropdown::-webkit-scrollbar {
      width: 4px;
      background-color: rgb(224, 224, 224);
      border-radius: 3rem;
    }
    &__dropdown::-webkit-scrollbar-thumb {
      background-color: $color-primary;
      border-radius: 3rem;
    }
    &__selected-sources {
      margin: 2.8rem 0 4rem;
      &--title {
        font-size: 1.4rem;
        font-family: $font-primary-bold;
        margin-bottom: 1.6rem;
        width: fit-content;
      }
      &--select-container {
        overflow-y: auto;
        max-height: 8rem;
        height: 100%;
        .message {
          font-family: $font-primary;
          text-align: center;
          width: 100%;
          margin-bottom: 0;
          font-size: 1.4rem;
        }
        .select {
          box-sizing: border-box;
          margin: 0 0.8rem 0.8rem 0.4rem;
          border-radius: 100px !important;
          color: #000000d9;
          font-variant: tabular-nums;
          list-style: none;
          font-feature-settings: tnum;
          display: inline-block;
          height: 2.8rem;
          padding: 0.5rem 1.6rem;
          white-space: nowrap;
          background-color: #d0dbff;
          border: 1px solid #3764fb;
          opacity: 1;
          transition: all 0.3s;
          position: relative;
          padding-right: 3rem;
          overflow: auto;
          p {
            margin-right: 0.5rem;
            margin-bottom: 0;
            font-size: 1.2rem;
            font-family: $font-primary;
            line-height: 1.4rem;
          }
          .icon {
            padding: 0.5rem;
            background-color: $color-white;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 0.5rem;
            font-size: 0.8rem;
            transform: rotate(45deg);
            cursor: pointer;
            width: fit-content;
            top: 50%;
            position: absolute;
            transform: translateY(-50%) rotate(45deg);
            right: 0.8rem;
            width: 1.8rem;
            height: 1.8rem;
          }
        }
      }
      &--select-container::-webkit-scrollbar-track {
        background-color: #ffffff3f;
      }
      &--select-container::-webkit-scrollbar {
        width: 4px;
        background-color: rgb(224, 224, 224);
        border-radius: 3rem;
      }
      &--select-container::-webkit-scrollbar-thumb {
        background-color: $color-primary;
        border-radius: 3rem;
      }
    }
  }
  </style>
  