<template>
  <div class="pdf-card">
    <div class="pdf-card__header">
      <img
        v-if="!isDocumentLink"
        src="@/assets/icons/pdf-file-format.png"
        alt="PDF Icon"
        class="pdf-card__header--image"
      />
      <img
        v-else
        src="@/assets/icons/document-file-icon.png"
        alt="PDF Icon"
        class="pdf-card__header--image"
      />
    </div>
    <div class="pdf-card__content">
      <h1 class="pdf-card__content--title">
        {{ pdfFileName }}
      </h1>
    </div>
    <div class="pdf-card__buttons">
      <button @click="!isDocumentLink ? openPDFModal() : downloadPdf()" class="pdf-card__buttons--btn">
        <img src="@/assets/icons/eye-icon.png" />
        <p>Preview</p>
      </button>
      <span v-if="!isDocumentLink" class="line"></span>
      <button
        v-if="!isDocumentLink"
        @click="downloadPdf()"
        class="pdf-card__buttons--btn"
      >
        <img src="@/assets/icons/download-icon.png" />
        <p>Download</p>
      </button>
      <template v-if="isLoggedInUser">
        <span class="line"></span>
        <button @click=" deletePDF(id)" class="pdf-card__buttons--btn">
          <i class="icon icon-dustbin"></i>
          <p>Remove</p>
        </button>
      </template>
    </div>
  </div>
  <ViewPDFModal
    :visible="visiblePDFModal"
    :url="pdfUrl"
    :pdfFileName="pdfFileName"
    @closeModal="closePDBModal()"
  />
  <delete-modal
    :visible="visibleDeleteModal"
    @handle-close="visibleDeleteModal = false"
    title="Are you sure you want to remove this?"
    confirmText="Yes, Remove"
    cancelText="No, Don't Remove"
    :loading="onRemoveFile"
    @delete-record="isArchiveContent ?  deleteFileFromArchive() : deleteFile()"
  ></delete-modal>
</template>

<script>
import ViewPDFModal from "./ViewPDFModal.vue";
import DeleteModal from "../BaseComponents/DeleteModal.vue";
import { mapGetters } from "vuex/dist/vuex.cjs.js";

export default {
  components: {
    ViewPDFModal,
    DeleteModal,
  },
  props: {
    pdfUrl: String,
    pdfFileName: String,
    id: String,
    isArchiveContent:{
      type: Boolean,
      default: () => (false)
    },
    archiveFolderId:{
      type: String,
      default: ()=>('')
    },
    isDocumentLink:{
      type: Boolean,
      default: () =>(false)
    }
  },
  data() {
    return {
      visiblePDFModal: false,
      visibleDeleteModal: false,
      onRemoveFile: false,
      contentToBeRemoved: "",
    };
  },
  computed: {
    ...mapGetters({
      isLoggedInUser: 'profile/isLoggedinUser',
    }),
    articlesContent() {
      return this.$store.getters["folders/getFolderArticleContent"];
    },
  },
  methods: {
    openPDFModal() {
      this.visiblePDFModal = true;
    },
    closePDBModal() {
      this.visiblePDFModal = false;
    },
    downloadPdf() {
      const link = document.createElement("a");
      link.href = this.pdfUrl;
      link.target = "_blank";
      link.download = this.pdfFileName;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    deletePDF(id) {
      this.contentToBeRemoved = id;
      this.visibleDeleteModal = true;
    },
    async deleteFile() {
      try {
        this.onRemoveFile = true;
        const payload = {
          folderId: this.$route.params.id,
          contentId: this.contentToBeRemoved,
        };
        const response = await this.$store.dispatch(
          "folders/deleteFolderContent",
          payload
        );
        if (response.status === 204) {
          this.visibleDeleteModal = false;
          const updatedFileList = this.articlesContent.findIndex(
            (val) => val.id === this.contentToBeRemoved
          );
          if (updatedFileList !== -1) {
            this.articlesContent.splice(updatedFileList, 1);
          }
        }
        this.onRemoveFile = false;
        this.$showToast({message:'Content removed successfully.', type:'success'})
      } catch (err) {
        console.log(err);
      }
    },
    async deleteFileFromArchive(){
      try {
          this.onRemoveFile = true;
            await this.$store.dispatch(
            "archive/deleteArchiveFoldersContent",
            {archiveFolderId: this.archiveFolderId, contentId: this.contentToBeRemoved}
          );
          this.onRemoveFile = false;
          this.visibleDeleteModal = false;
          this.$showToast({message:'Content removed successfully.', type:'success'})
      }
      catch(err){
        console.log(err)
      }
    }
  },
};
</script>

<style lang="scss">
.pdf-card {
  border: 1px solid $color-dark-grey-5;
  border-radius: 0.5rem;
  height: 100%;
  &__header {
    height: calc(100% - 10.8rem);
    width: 100%;
    border-start-end-radius: 0.5rem;
    border-start-start-radius: 0.5rem;
    &--image {
      height: 100%;
      width: 100%;
      border-start-end-radius: 0.5rem;
      border-start-start-radius: 0.5rem;
      object-fit: contain;
    }
  }
  &__content {
    max-width: 100%;
    border-top: 1px solid $color-dark-grey-5;
    border-bottom: 1px solid $color-dark-grey-5;
    padding: 1.7rem 1.6rem;
    &--title {
      color: $color-black;
      font-size: 1.4rem;
      font-family: $font-primary-medium;
      line-height: 1.8rem;
      margin-bottom: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1; /* number of lines to show */
      line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    &--btn {
      width: 100%;
      height: fit-content;
      margin: 1.9rem 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      border-radius: 0 !important;
      outline: none;
      background-color: transparent;
      cursor: pointer;
      img {
        width: 2rem;
        height: auto;
        margin-right: 1rem;
      }
      .icon {
        font-size: 1.5rem;
        margin-right: 1rem;
        color: $color-black;
      }
      p {
        font-size: 1.3rem;
        line-height: 2rem;
        color: $color-black;
        font-family: $font-primary-medium;
        margin-bottom: 0;
      }
    }
    .line {
      height: 2rem;
      width: 1px;
      background-color: rgba(0, 0, 0, 0.408);
      display: block;
    }
  }
}
</style>
