<template>
  <div class="connection-section">
    <a-row :gutter="[40, 18]">
      <a-col :xs="24" :sm="10" :md="10" :lg="6" :xl="6">
        <div class="connection-section__sidebar">
          <div class="connection-section__sidebar--header">
            <h4 class="title">Network Management</h4>
            <button class="btn" @click="addNewCategory()" v-if="isLoggedInUser" id="addTabBtn" title="addTabBtn">
              <i class="icon icon-add_plus"></i>
            </button>
          </div>
          <div class="all-connection-tabs">
            <div
              class="all-connection-tabs__tab"
              :class="{
                'all-connection-tabs__tab-active': selectedGroupId === -1,
              }"
              @click="fetchSelectedGroupUser(-1)"
            >
              <p>All Network<span class="count" v-if="friendsCount > 0">{{ formatTotal(friendsCount) }}</span></p>
            </div>
            <div
              v-if="groupsLoading"
              class="all-connection-tabs__skeleton-container"
            >
              <GroupsSkeletonLoader v-for="index in 8" :key="index" />
            </div>
            <div
              v-else
              v-for="group in audienceGroups"
              :key="group"
              class="all-connection-tabs__tab"
              :class="{
                'all-connection-tabs__tab-active': selectedGroupId === group.id,
              }"
              @click="fetchSelectedGroupUser(group)"
            >
              <p><span>{{ group.group_name }}</span> <span class="count" v-if="group.total > 0">{{ formatTotal(group.total) }}</span></p>
              <a-dropdown
                v-if="isLoggedInUser"
                @click.stop=""
                :trigger="['click']"
                placement="bottomRight"
                class="all-connection-tabs__tab--dropdown"
              >
                <a class="ant-dropdown-link">
                  <i
                    data-cy="groupActions"
                    class="icon icon-vertical_three-dots"
                  ></i>
                </a>
                <template #overlay>
                  <a-menu>
                    <a-menu-item @click.prevent="editGroupTitle(group)" key="0">
                      <a>Edit</a>
                    </a-menu-item>
                    <a-menu-item key="1">
                      <a
                        @click="showDeleteModal(group.id, 'group')"
                        class="remove-btn"
                        >Remove</a
                      >
                    </a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
            </div>
            <!-- <button class="all-connection-tabs__tab">
          <svg
            width="16"
            height="17"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.16667 15.5V10.5H8.83333V12.1667H15.5V13.8333H8.83333V15.5H7.16667ZM0.5 13.8333V12.1667H5.5V13.8333H0.5ZM3.83333 10.5V8.83333H0.5V7.16667H3.83333V5.5H5.5V10.5H3.83333ZM7.16667 8.83333V7.16667H15.5V8.83333H7.16667ZM10.5 5.5V0.5H12.1667V2.16667H15.5V3.83333H12.1667V5.5H10.5ZM0.5 3.83333V2.16667H8.83333V3.83333H0.5Z"
              fill="black"
            />
          </svg>
        </button> -->
          </div>
        </div>
      </a-col>
      <a-col :xs="24" :sm="14" :md="14" :lg="18" :xl="18">
        <div class="connection-section__body">
          <div class="connection-section__body--search-bar">
            <ConnectionsSearch />
          </div>
          <h2 v-if="selectedGroupName" class="connection-section__body--title">{{ selectedGroupName }}</h2>
          <a-row
            v-if="connectionsLoading"
            :gutter="[18, 22]"
            class="connection-section__body--connection-row"
          >
            <a-col
              v-for="index in 4"
              :key="index"
              :xs="12"
              :sm="12"
              :md="12"
              :lg="8"
              :xl="6"
            >
              <ConnectionsSkeletonLoader />
            </a-col>
          </a-row>
          <p
            v-if="!connectionsLoading && selectedGroupConnectionLength === 0"
            class="message"
          >
            No connections added
          </p>
          <a-row
            v-else-if="!connectionsLoading && connectionsList.length > 0"
            :gutter="[18, 22]"
            class="connection-section__body--connection-row"
          >
            <ConnectionListItemV2
              :xs="24"
              :sm="24"
              :md="12"
              :lg="8"
              :xl="6"
              v-for="(connection, index) in selectedGroupIndex === -1
                ? connectionsList
                : connectionsInGroup[selectedGroupIndex]"
              :key="index"
              :name="
                selectedGroupIndex === -1
                  ? connection.full_name
                  : connection.full_name
              "
              :imgUrl="
                selectedGroupIndex === -1
                  ? connection.profile_picture
                  : connection.profile_picture
              "
              :jobTitle="
                selectedGroupIndex === -1
                  ? connection.job_title
                  : connection.job_title"
              :mutualFriends="
                selectedGroupIndex === -1
                  ? connection?.mutual_friends?.body?.message
                  : connection?.mutual_friends"
              :mutualFriendsImage=" mutualFriendImage(connection)"
              :id="Number(connection.id)"
              :isLoggedInUser="isLoggedInUser"
              :isInConnection="selectedGroupIndex === -1 ? true : false"
              :isInGroup="selectedGroupIndex === -1 ? false : true"
              :connections="connection"
              :groupId="selectedGroupIndex.toString()"
              :user="connection"
              @show-add-connections-category-modal="
                showAddConnectionsGroupModal(
                  connection.id,
                  selectedGroupIndex === -1 && connection
                    ? connection.full_name
                    : connection.full_name,
                  selectedGroupIndex === -1 && connection
                    ? connection.profile_picture
                    : connection.profile_picture,
                  selectedGroupIndex === -1 && connection
                    ? connection.job_title
                    : connection.job_title
                )
              "
            />
          </a-row>
        </div>
      </a-col>
    </a-row>
  </div>
  <add-to-category-modal
    v-if="addToCatergoryModalVisible"
    :selected-conn="selectedConnection"
    :audience-groups="audienceGroups"
    :visible="addToCatergoryModalVisible"
    @close-modal="updateAudience"
    :footer="null"
    @add-new-category="showCategoryModal"
  ></add-to-category-modal>
  <add-new-category-modal
    ref="group-modal"
    :visible="addNewCategoryModalVisible"
    :footer="null"
    @close-modal="updateGroups"
    :action="action"
  >
  </add-new-category-modal>
  <delete-modal
    :visible="deleteModalVisible"
    @handle-close="deleteModalVisible = false"
    @close="deleteModalVisible = false"
    title="Are you sure you want to remove this?"
    confirmText="Yes, Remove"
    cancelText="No, Don't Remove"
    message="You will not be able to recover this. The Group will be permanently removed."
    @delete-record="deleteGroup"
    :loading="deleteloading"
  ></delete-modal>
  <message-toaster
    :visible="showMessageModal"
    :message="message"
    @handle-close="showMessageModal = false"
  >
  </message-toaster>
</template>

<script>
import ConnectionListItemV2 from "../BaseComponents/ConnectionListItemV2.vue";
import ConnectionsSearch from "./ConnectionsSearch.vue";
import ConnectionsSkeletonLoader from "./SkeletonLoaders/ConnectionSkeletonLoader.vue";
import GroupsSkeletonLoader from "./SkeletonLoaders/GroupsSkeletonLoader.vue";
import AddToCategoryModal from "../ProfilePageComponents/AddToCategoryModal.vue";
import AddNewCategoryModal from "../ProfilePageComponents/AddNewCategoryModal.vue";
import DeleteModal from "../BaseComponents/DeleteModal.vue";
import MessageToaster from "../BaseComponents/MessageToaster.vue";

export default {
  components: {
    ConnectionsSkeletonLoader,
    ConnectionListItemV2,
    ConnectionsSearch,
    GroupsSkeletonLoader,
    AddToCategoryModal,
    AddNewCategoryModal,
    DeleteModal,
    MessageToaster,
  },
  data() {
    return {
      addToCatergoryModalVisible: false,
      addNewCategoryModalVisible: false,
      selectedConnection: {},
      selectedGroupIndex: -1,
      action: "",
      selectedGroupId: -1,
      isInConnection: true,
      deleteModalVisible: false,
      deleteloading: false,
      showMessageModal: false,
      message: {},
      selectedGroupName: 'All Network'
    };
  },
  mounted() {
    this.emitter.on("SuccesMsgOnNetwork", (message) => {
      this.$showToast({'message':message.message, type:message.type})
    });
  },
  computed: {
    connectionsLoading() {
      return this.$store.getters["profile/getConnectionsLoader"];
    },
    groupsLoading() {
      return this.$store.getters["profile/getGroupsLoader"];
    },
    audienceGroups() {
      return this.$store.getters["profile/getAudienceGroups"];
    },
    connectionsList() {
      return this.$store.getters["profile/getFriends"];
    },
    isLoggedInUser() {
      return this.$store.getters["profile/isLoggedinUser"];
    },
    friendsCount() {
      return this.$store.getters["profile/getTotalFriendsCount"];
    },
    connectionsInGroup() {
      return this.$store.getters["profile/getConnectionsInGroup"];
    },
    selectedGroupConnectionLength() {
      // checks for zero connections in connections list and connectionsInGroup
      return this.selectedGroupIndex === -1
        ? this.connectionsList.length
        : this.connectionsInGroup[this.selectedGroupIndex]?.length;
    },
  },
  methods: {
    pdateAudience(msg) {
      this.addToCatergoryModalVisible = false;
      this.$emit("update-audience", msg);
    },
    mutualFriendImage(connection){
      if( this.selectedGroupIndex === -1  ) {
        return connection.mutual_friends != null ? connection.mutual_friends.body.user.profile_picture : ''
      } else{
        return connection.mutual_friends_image
      }
    },
    showCategoryModal() {
      this.action = "add";
      this.addNewCategoryModalVisible = true;
      this.addToCatergoryModalVisible = false;
    },
    showAddConnectionsGroupModal(id, name, pic, jobTitle) {
      this.selectedConnection = {
        id: id,
        profile_pic: pic,
        name: name,
        job_title: jobTitle,
      };
      this.addToCatergoryModalVisible = true;
    },
    async getAllGroups() {
      try {
        const payload = {
          user_id: this.$route.params.id,
        };
        await this.$store.dispatch("profile/loadGroups", payload);
      } catch (err) {
        console.log(err);
      }
    },
   async updateAudience(msg) {
      await this.getAllGroups();
      this.addToCatergoryModalVisible = false;
      if (msg.title) {
        this.message = msg;
        this.showMessageModal = true;
      }
    },
    async fetchSelectedGroupUser(group) {
      if (group === -1) {
        this.selectedGroupId = -1;
        this.selectedGroupIndex = -1;
        this.selectedGroupName = 'All Network'
      } else {
        this.selectedGroupId = group.id;
        this.selectedGroupIndex = group.id;
        this.selectedGroupName = group.group_name;
        await this.$store.dispatch("profile/fetchGroupAudience", group.id);
      }
    },
    updateGroups() {
      this.audienceGroups = this.$store.getters["profile/getAudienceGroups"];
      this.addNewCategoryModalVisible = false;
    },
    addNewCategory() {
      this.action = '';
      this.addNewCategoryModalVisible = true;
    },
    editGroupTitle(group) {
      this.action = "edit";
      this.addNewCategoryModalVisible = true;
      this.$refs["group-modal"].selectGroup(group);
    },
    showDeleteModal(id) {
      this.selectedGroupId = id;
      this.deleteModalVisible = true;
    },
    async deleteGroup() {
      this.deleteloading = true;
      try {
        const response = await this.$store.dispatch(
          "profile/deleteGroup",
          this.selectedGroupId
        );
        if (response.status === 204) {
          // this.message = {
          //   title: "Removed successfully.",
          //   desc: "",
          //   type: "success",
          // };
          this.deleteModalVisible = false;
          // this.showMessageModal = true;
          this.audienceGroups = this.$store.getters[
            "profile/getAudienceGroups"
          ];
          this.$showToast({message:'Network group removed successfully.', type:'success'})
        }
      } catch (err) {
        this.$showToast({message:'Something went wrong. Please try again later', type:'failure'})
        // this.message = {
        //   title: "Something went wrong",
        //   desc: "Please Try Again later. Sorry for the inconvenience",
        //   type: "failure",
        // };
        this.deleteModalVisible = false;
        // this.showMessageModal = true;
      } finally {
        this.selectedRecordId = null;
        this.deleteloading = false;
      }
    },
    formatTotal(total) {
      if (total < 10) {
        return `0${total}`;
      } else if (total > 99) {
        return '99+';
      } else {
        return total;
      }
    },
  },
};
</script>

<style lang="scss">
.connection-section {
  padding: 1rem 2.6rem 3rem;
  &__sidebar {
    &--header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 4rem;
      .title {
        font-size: 2rem;
        font-family: $font-primary-medium;
        margin-bottom: 0;
        line-height: 2.3rem;
        color: $color-black;
        @include respond(tab-port) {
          font-size: 2.2rem;
          line-height: 2.8rem;
        }
      }
      .btn {
        background-color: $color-primary;
        height: 3rem;
        width: 3rem;
        border: none;
        outline: none;
        border-radius: 100% !important;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .icon {
          color: $color-white;
          font-size: 1.2rem;
        }
      }
    }
    .all-connection-tabs {
      width: 100%;
      &__tab {
        width: 100%;
        padding: 1.8rem 2rem;
        border-bottom: 1px solid rgba(135, 149, 157, 0.3);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        transition: 0.2s all ease-in-out;
        p {
          font-size: 1.4rem;
          line-height: 1.6rem;
          color: $color-black;
          margin-bottom: 0;
          font-family: $font-primary-regular;
          display: flex;
          align-items: center;
          gap: 1rem;
          @include respond(tab-port) {
            font-size: 1.8rem;
            line-height: 2.2rem;
          }
          span {
            opacity: 0.7;
          }
          .count {
            background-color: #ececec;
            color: $color-black;
            font-family: $font-primary-medium;
            font-size: 1.2rem;
            line-height: 1.4rem;
            width: 2.4rem;
            height: 2.4rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
            opacity: 1;
          }
        }
        &--dropdown {
          margin-left: 1.5rem;
          .icon {
            color: $color-black;
            font-size: 1.5rem;
          }
        }
        &:hover {
          background-color: #ebebeb59;
        }
      }
      &__tab-active {
        &::before {
          content: '';
          position: absolute;
          right: 0;
          width: 4px;
          height: 100%;
          background-color: $color-primary;
        }
        p {
          span {
            font-family: $font-primary-medium;
            opacity: 1;
          }
        }
      }
      &__skeleton-container {
        .ant-skeleton {
          .ant-skeleton-content {
            .ant-skeleton-title {
              margin: 2rem 0 0;
              width: 100% !important;
              height: 4.4rem;
            }
          }
        }
      }
    }
  }
  &__body {
    min-height: 40rem;
    &--title {
      font-size: 2rem;
      font-family: $font-primary-medium;
      margin-bottom: 2rem;
      line-height: 2.3rem;
      color: $color-black;
      @include respond(tab-port) {
        font-size: 2.2rem;
        line-height: 2.8rem;
      }
    }
    .message {
      font-size: 1.6rem;
      margin: 0;
      color: $color-black;
      opacity: 0.8;
      font-family: $font-primary;
      text-align: center;
    }
    &--connection-row {
      .connection-skeleton-loader {
        flex-direction: column;
        .ant-skeleton-header {
          margin-bottom: 1rem;
          padding: 0;
          .ant-skeleton-avatar {
            border-radius: 100%;
            height: 10rem !important;
            width: 10rem !important;
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
}
</style>
