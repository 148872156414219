<template>
  <a-modal :visible="visible" @cancel="closeModal" :footer="null" class="add-contributors-modal" :title="null">
    <div class="add-contributors-modal-container">
      <div class="add-contributors-modal-container__header">
        <h2 class="add-contributors-modal-container__header--title" v-if="isSeeAllContacts === true">
          All Contacts
        </h2>
        <h2 class="add-contributors-modal-container__header--title" v-else>
          Add Contributors
        </h2>
      </div>
      <div class="add-contributors-modal-container__body">
        <div class="add-contributors-modal-container__body--search-bar">
          <div class="search">
            <i class="icon icon-search"></i>
            <input v-model="searchQuery" class="search-input" type="text" placeholder="Search with name or email" />
          </div>
          <div v-if="dropdown" class="dropdown">
             <div class="dropdown__user" v-for="user in filteredContributors" :key="user.id" @click="addToSelectedContributor(user)">
              <img :src="user.profile_picture" alt="Image" />
              <p>{{user.full_name}}</p>
             </div>
          </div>
        </div>
        <!-- <div class="add-contributors-modal-container__body--skeleton" v-if="loadingContributors">
          <skeleton-loader
            v-for="index in 4"
            :key="index"
          ></skeleton-loader>
        </div> -->
        <div v-if="filteredContributors.length === 0 && selectedContributors.length === 0 && searchQuery && isSeeAllContacts !== true" class="add-contributors-modal-container__body--message">
          No contributors found.
        </div>
        <!-- <div v-else class="add-contributors-modal-container__body--contributors">
          <div class="contributor" v-for="contributor in filteredContributors" :key="contributor.id">
            <div class="contributor__image">
              <img :src="contributor.profile_picture" alt="Image" />
            </div>
            <div class="contributor__name">
              <h1>{{ contributor.full_name }}</h1>
              <p>{{ contributor.email }}</p>
            </div>
            <a-checkbox :checked="isChecked(contributor.id)" @change="toggleContributor(contributor.id)" />
          </div>
        </div> -->
        <div class="add-contributors-modal-container__body--contributors" v-if="isSeeAllContacts === true">
          <button
            class="contributor"
            v-for="contributor in filteredContributors"
            :key="contributor.id"
          >
            <div class="contributor__details">
              <img class="logo" :src="contributor.profile_picture" />
              <p>{{ contributor.full_name }}</p>
            </div>
            <i v-if="isLoggedInUser" @click.stop="toggleDeleteModal(contributor)" class="close-icon icon-add_plus"></i>
          </button>
        </div>
        <div class="add-contributors-modal-container__body--contributors" v-else>
          <button
            class="contributor"
            v-for="contributor in selectedContributors"
            :key="contributor.id"
          >
            <div class="contributor__details">
              <img class="logo" :src="contributor.profile_picture" />
              <p>{{ contributor.full_name }}</p>
            </div>
            <i @click.stop="removeSelectedContributor(contributor.id)" class="close-icon icon-add_plus"></i>
          </button>
        </div>
      </div>
      <div class="add-contributors-modal-container__footer">
        <a-button :class="{ 'disable-save-btn': selectedContributors.length === 0 }" @click="addContributors"
          :loading="isLoading" v-if="isSeeAllContacts === false">Add</a-button>
          <!-- <a-button @click="updateContributors"
          :loading="isLoading" v-else>Update</a-button> -->
      </div>
    </div>
  </a-modal>
  <DeleteModal 
    :visible="showRemoveContributorModal"
    @handle-close="showRemoveContributorModal = false"
    title="Are you sure you want to remove this?"
    confirmText="Yes, Remove"
    cancelText="No, Don't Remove"
    :loading="removeLoader"
    @delete-record="removeContributors"
  />
  <message-toaster
    :visible="showMessageModal"
    :message="message"
    @handle-close="showMessageModal = false"
  >
  </message-toaster>
</template>

<script>
import DeleteModal from '../BaseComponents/DeleteModal.vue';
import MessageToaster from '../BaseComponents/MessageToaster.vue';
import {mapGetters} from 'vuex'
export default {
  data() {
    return {
      isLoading: false,
      loadingContributors:false,
      searchQuery: "",
      checkedContributors: [],
      dropdown: false,
      selectedContributors:[],
      showRemoveContributorModal: false,
      removeLoader: false,
      showMessageModal: false
    };
  },
  components: {
    DeleteModal,
    MessageToaster
    // skeletonLoader
  },
  watch: {
    searchQuery(val) {
      if (val == "") {
        this.dropdown = false;
      } else {
        this.dropdown = this.isSeeAllContacts === true ? false : true;
        // this.fetchContributors();
      }
    }
  },
  props: {
    visible: Boolean,
    contributors: Array,
    isSeeAllContacts: Boolean
  },
  computed: {
    ...mapGetters({
      isLoggedInUser: 'profile/isLoggedinUser',
    }),
    allContributors() {
      return this.$store.getters["feed/getAllContributors"];
    },
    userId() {
      return this.$store.getters["profile/currentLoginUser"];
    },
    filteredContributors() {
      if (this.isSeeAllContacts !== true){
        if (!this.searchQuery) {
          return this.allContributors;
        }
        const query = this.searchQuery.toLowerCase();
        const searchedArray = this.allContributors.filter(contributor => !this.contributors.find(addedContributors=>addedContributors.id === contributor.id))
        const searchedResult = searchedArray.filter(contributor => !this.selectedContributors.find(selectedContributor=> selectedContributor.id === contributor.id)).filter(contributor =>
          contributor.full_name.toLowerCase().includes(query) ||
          contributor.email.toLowerCase().includes(query)
        )
        return searchedResult
      }else{
        return this.contributors.filter(selectedContributor=> selectedContributor.full_name.toLowerCase().includes(this.searchQuery.toLowerCase()) || selectedContributor.email.toLowerCase().includes(this.searchQuery.toLowerCase()))
      }
    },
    
  },
  async mounted() {
    //  this.getAllContributors();
    // await this.getFollowes('not_contributors')
  },
  methods: {
    async addContributors() {
      try {
        this.isLoading = true;
        const payload = {
          add_contributors: this.selectedContributors.map(contributor=>contributor.id),
          remove_contributors: []
        }
          await this.$store.dispatch("feed/addContributor", payload);
          await this.getFollowes('not_contributors')
          await this.getFollowes();
          this.closeModal()
          this.$showToast({message:'Contributor added successfully', type:'success'})
          // this.showMessageModal = true
          // this.message = {
          //   title: "Contributors add successfully",
          //   type: "success",
          // };
      } catch (error) {
        console.log(error);
        this.$showToast({message:'Something went wrong. Please try again later.', type:'failure'})
      } finally {
        this.isLoading = false
      }
    },
    async getFollowes(relation='contributors') {
      await this.$store.dispatch("feed/fetchContributorsAndOrganizations", {relation});
    },
    closeModal() {
      this.selectedContributors = []
      this.$emit("closeFeedUploadModal", false);
    },
    isChecked(id) {
      return this.checkedContributors.includes(id);
    },
    toggleContributor(id) {
      const index = this.checkedContributors.indexOf(id);
      if (index > -1) {
        this.checkedContributors.splice(index, 1);
      } else {
        this.checkedContributors.push(id);
      }

    },
    async getAllContributors() {
      try {
        this.loadingContributors = true;
        const payload = {
          id: this.userId,
          relation:'not_followers'
        };
        await this.$store.dispatch("feed/getFollowed", payload);
      } catch (err) {
        console.log(err);
      } finally {
        this.loadingContributors = false;
      }
    },
    addToSelectedContributor(contributor){
      this.selectedContributors.push(contributor)
      this.searchQuery = ''
    },
    removeSelectedContributor(contributorId){
      this.selectedContributors = this.selectedContributors.filter(contributor=>contributor.id !== contributorId)
    },
    async removeContributors(){
      
      try{
        this.removeLoader = true
        const payload = {
          add_contributors: [],
          remove_contributors: this.selectedContributors.map(contributor=>contributor.id)
        }
        await this.$store.dispatch("feed/addContributor", payload);
        const updatedContributors = this.contributors.filter(contributor=>contributor.id !== payload.remove_contributors[0])
        this.$store.commit('feed/setFollowed', updatedContributors)
        this.removeLoader = false
        this.showRemoveContributorModal = !this.showRemoveContributorModal
        this.selectedContributors = []
        this.$showToast({message:'Contributor removed successfully', type:'success'})
      }
      catch(err){
        this.removeLoader = false
        this.$showToast({message:'Something went wrong. Please try again later.', type:'failure'})
      }
    },
    toggleDeleteModal(contributor){
      this.showRemoveContributorModal = !this.showRemoveContributorModal
      this.selectedContributors.push(contributor)
    }
  },
};
</script>

<style lang="scss">
.add-contributors-modal {
  width: 50rem !important;
  top: 5rem !important;

  .ant-modal-content {
    box-shadow: 0px 0px 94px #00000047;

    .ant-modal-close {
      margin: 2.3rem 1.8rem 0 0 !important;
    }

    .ant-modal-body {
      .add-contributors-modal-container {
        border-radius: 1rem;

        &__header {
          padding: 2.5rem;

          &--title {
            font-size: 2.4rem;
            font-family: $font-primary-bold;
            line-height: normal;
            margin-bottom: 0;
            color: $color-black;
          }
        }

        &__body {
          &--search-bar {
            width: calc(100% - 5rem);
            margin: 0 2.5rem 2.8rem;
            position: relative;
            .search {
              display: flex;
              align-items: center;
              background-color: $color-white;
              padding: 0 1.7rem;
              border-radius: 0.5rem;
              border: 1px solid $color-dark-grey-5;
              .icon {
                font-size: 1.4rem;
                opacity: 0.6;
                color: $color-black;
              }

              .search-input {
                font-size: 1.6rem;
                font-family: $font-primary;
                line-height: normal;
                padding: 1rem 1.2rem;
                border: none;
                outline: none;
                width: 100%;
                background-color: transparent;

                &::placeholder {
                  color: $color-black;
                  opacity: 0.4;
                }
              }
            }
            .dropdown {
              position: absolute;
              width: 100%;
              background-color: $color-white;
              box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
              border-end-end-radius: 0.5rem;
              border-end-start-radius: 0.5rem;
              max-height: 10rem;
              overflow-y: auto;
              overflow-x: hidden;
              z-index: 2;
              &__loader {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 1rem 0 !important;
                .ant-spin {
                  .anticon {
                    font-size: 2rem !important;
                  }
                }
              }
              &__message {
                font-size: 1.4rem;
                font-family: $font-primary;
                line-height: normal;
                margin: 2rem 0;
                color: $color-black;
                text-align: center;
              }
              &__user {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                cursor: pointer;
                border-bottom: 1px solid $color-dark-grey-5;
                padding: 1rem 1.5rem;
                background-color: $color-white;
                transition: background-color 0.3s ease-in-out;
                &:hover {
                  background-color: $color-light-grey-2;
                }
                img {
                  width: 3rem;
                  height: 3rem;
                  border-radius: 50%;
                  object-fit: cover;
                  background-color: #d6d6d6;
                }
                p {
                  font-size: 1.4rem;
                  font-family: $font-primary-medium;
                  line-height: normal;
                  margin-left: 1rem;
                  color: $color-black;
                  margin-bottom: 0;
                }
                &:last-child {
                  border-bottom: 0;
                }
              }
              &::-webkit-scrollbar-track {
                background-color: transparent;
              }

              &::-webkit-scrollbar {
                width: 0.3rem;
                height: 0.3rem;
                background-color: transparent;
                border-radius: 3rem;
              }

              &::-webkit-scrollbar-thumb {
                background-color: $color-primary;
                border-radius: 3rem;
              }
            }
          }
          &--message {
            font-size: 1.6rem;
            font-family: $font-primary;
            line-height: 2.2rem;
            margin-bottom: 0;
            color: $color-black;
            padding: 0 2rem;
            margin: 4rem 0;
            text-align: center;
          }
          &--contributors {
            overflow-y: auto;
            max-height: 45vh;
            padding: 0 2.5rem;

            // .contributor {
            //   display: flex;
            //   align-items: center;
            //   padding: 1.5rem 2.6rem;
            //   border-bottom: 1px solid $color-dark-grey-5;

            //   &__image {
            //     width: 4rem;
            //     height: 4rem;
            //     border-radius: 100%;

            //     img {
            //       width: 100%;
            //       height: 100%;
            //       object-fit: cover;
            //       border-radius: 100%;
            //       background-color: #ececec;
            //     }
            //   }

            //   &__name {
            //     margin-left: 1rem;

            //     h1 {
            //       font-family: $font-primary-medium;
            //       font-size: 1.6rem;
            //       color: $color-black;
            //       line-height: 2rem;
            //       margin-bottom: 0;
            //     }

            //     p {
            //       font-family: $font-primary;
            //       font-size: 1.4rem;
            //       color: $color-black;
            //       line-height: 1.8rem;
            //       margin-bottom: 0;
            //       opacity: 0.6;
            //     }
            //   }

            //   .ant-checkbox-wrapper {
            //     margin-left: auto;
            //     font-size: 1.6rem;
            //     font-family: $font-secondary;
            //     width: fit-content;
            //     margin-bottom: 0;
            //     line-height: 2rem;

            //     .ant-checkbox {
            //       .ant-checkbox-inner {
            //         border: 0.18rem solid rgba(128, 128, 128, 0.377);
            //         height: 18px;
            //         width: 18px;
            //         border-radius: 0;
            //       }

            //       .ant-checkbox-input {
            //         width: fit-content;
            //       }
            //     }
            //   }

            //   .ant-checkbox-wrapper-checked {
            //     .ant-checkbox-checked {
            //       .ant-checkbox-inner {
            //         background-color: $color-primary;
            //         border-color: $color-primary;
            //         height: 18px;
            //         width: 18px;
            //         border-radius: 0;
            //       }
            //     }
            //   }
            // }
            .contributor {
              box-sizing: border-box;
              margin: 0 0.8rem 0.8rem 0.4rem;
              border-radius: 100px !important;
              color: #000000d9;
              font-variant: tabular-nums;
              list-style: none;
              font-feature-settings: tnum;
              display: inline-block;
              height: 3.5rem;
              padding: 0.5rem 3rem 0.5rem 1rem;
              white-space: nowrap;
              background-color: #d0dbff;
              border: 1px solid #3764fb;
              opacity: 1;
              transition: all 0.3s;
              position: relative;
              overflow: hidden;
              &__details {
                display: flex;
                align-items: center;
                .logo{ 
                  width: 1.8rem;
                  height: 1.8rem;
                  margin-right: 0.5rem;
                  object-fit: contain;
                  border-radius: 100%;
                }
                .border-radius {
                  border-radius: 100%;
                  width: 1.7rem;
                  height: 1.7rem;
                  margin-bottom: 0.2rem;
                }
                p {
                  margin-right: 0.5rem;
                  margin-bottom: 0;
                  font-size: 1.3rem;
                  font-family: $font-primary;
                  line-height: 1.5rem;
                }
              }
              .close-icon {
                padding: 0.5rem;
                background-color: $color-white;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 0.8rem;
                transform: rotate(45deg);
                cursor: pointer;
                width: fit-content;
                top: 50%;
                position: absolute;
                transform: translateY(-50%) rotate(45deg);
                right: 0.8rem;
                width: 1.8rem;
                height: 1.8rem;
                margin: 0;
              }
            }

            &::-webkit-scrollbar-track {
              background-color: transparent;
            }

            &::-webkit-scrollbar {
              width: 0.3rem;
              height: 0.3rem;
              background-color: transparent;
              border-radius: 3rem;
            }

            &::-webkit-scrollbar-thumb {
              background-color: $color-primary;
              border-radius: 3rem;
            }
          }
          &--skeleton {
            padding: 1.5rem 2.5rem 0;
            .connection-card-skeleton {
              margin: 0 0 2rem !important;
              .ant-skeleton-header {
                .ant-skeleton-avatar {
                  border-radius: 100%;
                }
              } 
            }
          }
        }

        &__footer {
          padding: 2.5rem 2.5rem;
          margin-top: -1px;

          .ant-btn {
            width: 10rem;
            background-color: $color-primary;
            border: none;
            outline: none;
            height: 4rem;
            font-size: 1.4rem;
            font-family: $font-primary-medium !important;
            color: $color-white;
            margin-left: auto;
            display: block;

            span {
              line-height: normal;
            }

            .anticon {
              .anticon-spin {
                font-size: 1.8rem;
                color: $color-primary;
              }
            }

            &:hover,
            &:focus {
              background-color: $color-primary;
              color: $color-white;
            }
          }

          .disable-save-btn {
            pointer-events: none;
            opacity: 0.5;
          }
        }
      }
    }
  }
}
</style>
