<template>
    <div class="article-grid-view">
      <a-row :gutter="22" v-if="searchingArticles">
        <a-col
          :xs="24"
          :sm="12"
          :md="12"
          :lg="8"
          v-for="index in 12"
          :key="index"
        >
          <article-card-skeleton></article-card-skeleton>
        </a-col>
    </a-row>
    <template v-else>
    <div v-if="$store.getters['discover/getLimitError']" class="message">
      Due to multiple access attempts, access in not available for 10 minutes. <br> Subscribe to experience the News SuperIntelligence environment.
      <a-button @click="showSubscriptionModal()">Subscribe</a-button>
    </div>
    <div v-if="articles.length === 0 && !$store.getters['discover/getLimitError']" class="message">
      No results found
      <span v-if="$store.getters['discover/getSearchQuery']">
        for
        <b
          ><q>{{ $store.getters["discover/getSearchQuery"] }}</q></b
        >
      </span>
    </div>
    <!-- <a-row v-else :gutter="[18, 10]">
      <a-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12" class="article-grid-view-col">
        <a-row :gutter="[18, 18]">
          <a-col v-if="articles[0]" :xs="24" :sm="12" :md="24" :lg="24" :xl="24">
             <article-card
              :title="articles[0].title[0]"
              :author-name="articles[0].authors[0]"
              :text="articles[0].description[0]"
              :image="articles[0].image[0]"
              :sourceLogo="articles[0].logo[0]"
              :source="articles[0].article_url.source"
              :profile-photo="'profile-pic.webp'"
              :url="articles[0].article_url.url"
              :id="articles[0].id[0]"
              :date="articles[0].pub_date[0]"
              :showShareButton="true"
              :showDltBtn="showDeleteButton"
              :showSaveBtn="true"
              :showUnsaveBtn="showUnsaveButton"
              :ratings="articles[0].rating"
              :aggregatedRating="articles[0].aggregated_rating"
              :userReaction="articles[0].user_reaction"
              :totalArticleReactions="articles[0].article_reactions"
              :userReactionRepresentation="articles[0].users_reaction_representation"
              :isInlineRatingColumn="12"
              :cardSize="true"
            ></article-card> 
          </a-col>
          <a-col v-if="articles[5]" :xs="24" :sm="12" :md="12" :lg="24" :xl="12">
            <article-card
              :title="articles[5].title[0]"
              :author-name="articles[5].authors[0]"
              :text="articles[5].description[0]"
              :image="articles[5].image[0]"
              :sourceLogo="articles[5].logo[0]"
              :source="articles[5].article_url.source"
              :profile-photo="'profile-pic.webp'"
              :url="articles[5].article_url.url"
              :id="articles[5].id[0]"
              :date="articles[5].pub_date[0]"
              :showShareButton="true"
              :showDltBtn="showDeleteButton"
              :showSaveBtn="true"
              :showUnsaveBtn="showUnsaveButton"
              :ratings="articles[5].rating"
              :aggregatedRating="articles[5].aggregated_rating"
              :userReaction="articles[5].user_reaction"
              :totalArticleReactions="articles[5].article_reactions"
              :userReactionRepresentation="articles[5].users_reaction_representation"
            ></article-card>
          </a-col>
          <a-col v-if="articles[6]" :xs="24" :sm="12" :md="12" :lg="24" :xl="12">
            <article-card
              :title="articles[6].title[0]"
              :author-name="articles[6].authors[0]"
              :text="articles[6].description[0]"
              :image="articles[6].image[0]"
              :sourceLogo="articles[6].logo[0]"
              :source="articles[6].article_url.source"
              :profile-photo="'profile-pic.webp'"
              :url="articles[6].article_url.url"
              :id="articles[6].id[0]"
              :date="articles[6].pub_date[0]"
              :showShareButton="true"
              :showDltBtn="showDeleteButton"
              :showSaveBtn="true"
              :showUnsaveBtn="showUnsaveButton"
              :ratings="articles[6].rating"
              :aggregatedRating="articles[6].aggregated_rating"
              :userReaction="articles[6].user_reaction"
              :totalArticleReactions="articles[6].article_reactions"
              :userReactionRepresentation="articles[6].users_reaction_representation"
            ></article-card>
          </a-col>
          <a-col v-if="articles[7]" :xs="24" :sm="12" :md="24" :lg="24" :xl="24">
            <article-card
              :title="articles[7].title[0]"
              :author-name="articles[7].authors[0]"
              :text="articles[7].description[0]"
              :image="articles[7].image[0]"
              :sourceLogo="articles[7].logo[0]"
              :source="articles[7].article_url.source"
              :profile-photo="'profile-pic.webp'"
              :url="articles[7].article_url.url"
              :id="articles[7].id[0]"
              :date="articles[7].pub_date[0]"
              :showShareButton="true"
              :showDltBtn="showDeleteButton"
              :showSaveBtn="true"
              :showUnsaveBtn="showUnsaveButton"
              :ratings="articles[7].rating"
              :aggregatedRating="articles[7].aggregated_rating"
              :userReaction="articles[7].user_reaction"
              :totalArticleReactions="articles[7].article_reactions"
              :userReactionRepresentation="articles[7].users_reaction_representation"
              :isInlineRatingColumn="12"
              :cardSize="true"
            ></article-card>
          </a-col>
        </a-row>
      </a-col>
      <a-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12" class="article-grid-view-col">
        <a-row :gutter="[18, 18]" class="right-grid">
          <a-col v-if="articles[1]" :xs="24" :sm="12" :md="24" :lg="24" :xl="12">
            <article-card
              :title="articles[1].title[0]"
              :author-name="articles[1].authors[0]"
              :text="articles[1].description[0]"
              :image="articles[1].image[0]"
              :sourceLogo="articles[1].logo[0]"
              :source="articles[1].article_url.source"
              :profile-photo="'profile-pic.webp'"
              :url="articles[1].article_url.url"
              :id="articles[1].id[0]"
              :date="articles[1].pub_date[0]"
              :showShareButton="true"
              :showDltBtn="showDeleteButton"
              :showSaveBtn="true"
              :showUnsaveBtn="showUnsaveButton"
              :ratings="articles[1].rating"
              :aggregatedRating="articles[1].aggregated_rating"
              :userReaction="articles[1].user_reaction"
              :totalArticleReactions="articles[1].article_reactions"
              :userReactionRepresentation="articles[1].users_reaction_representation"
            ></article-card>
          </a-col>
          <a-col v-if="articles[2]" :xs="24" :sm="12" :md="12" :lg="24" :xl="12">
            <article-card
              :title="articles[2].title[0]"
              :author-name="articles[2].authors[0]"
              :text="articles[2].description[0]"
              :image="articles[2].image[0]"
              :sourceLogo="articles[2].logo[0]"
              :source="articles[2].article_url.source"
              :profile-photo="'profile-pic.webp'"
              :url="articles[2].article_url.url"
              :id="articles[2].id[0]"
              :date="articles[2].pub_date[0]"
              :showShareButton="true"
              :showDltBtn="showDeleteButton"
              :showSaveBtn="true"
              :showUnsaveBtn="showUnsaveButton"
              :ratings="articles[2].rating"
              :aggregatedRating="articles[2].aggregated_rating"
              :userReaction="articles[2].user_reaction"
              :totalArticleReactions="articles[2].article_reactions"
              :userReactionRepresentation="articles[2].users_reaction_representation"
            ></article-card>
          </a-col>
          <a-col v-if="articles[3]" :xs="24" :sm="12" :md="24" :lg="24" :xl="24">
            <article-card
              :title="articles[3].title[0]"
              :author-name="articles[3].authors[0]"
              :text="articles[3].description[0]"
              :image="articles[3].image[0]"
              :sourceLogo="articles[3].logo[0]"
              :source="articles[3].article_url.source"
              :profile-photo="'profile-pic.webp'"
              :url="articles[3].article_url.url"
              :id="articles[3].id[0]"
              :date="articles[3].pub_date[0]"
              :showShareButton="true"
              :showDltBtn="showDeleteButton"
              :showSaveBtn="true"
              :showUnsaveBtn="showUnsaveButton"
              :ratings="articles[3].rating"
              :aggregatedRating="articles[3].aggregated_rating"
              :userReaction="articles[3].user_reaction"
              :totalArticleReactions="articles[3].article_reactions"
              :userReactionRepresentation="articles[3].users_reaction_representation"
              :isInlineRatingColumn="12"
              :cardSize="true"
            ></article-card>
          </a-col>
          <a-col v-if="articles[4]" :xs="24" :sm="12" :md="12" :lg="24" :xl="12">
            <article-card
              :title="articles[4].title[0]"
              :author-name="articles[4].authors[0]"
              :text="articles[4].description[0]"
              :image="articles[4].image[0]"
              :sourceLogo="articles[4].logo[0]"
              :source="articles[4].article_url.source"
              :profile-photo="'profile-pic.webp'"
              :url="articles[4].article_url.url"
              :id="articles[4].id[0]"
              :date="articles[4].pub_date[0]"
              :showShareButton="true"
              :showDltBtn="showDeleteButton"
              :showSaveBtn="true"
              :showUnsaveBtn="showUnsaveButton"
              :ratings="articles[4].rating"
              :aggregatedRating="articles[4].aggregated_rating"
              :userReaction="articles[4].user_reaction"
              :totalArticleReactions="articles[4].article_reactions"
              :userReactionRepresentation="articles[4].users_reaction_representation"
            ></article-card>
          </a-col>
          <a-col v-if="articles[8]" :xs="24" :sm="12" :md="24" :lg="24" :xl="12">
            <article-card
              :title="articles[8].title[0]"
              :author-name="articles[8].authors[0]"
              :text="articles[8].description[0]"
              :image="articles[8].image[0]"
              :sourceLogo="articles[8].logo[0]"
              :source="articles[8].article_url.source"
              :profile-photo="'profile-pic.webp'"
              :url="articles[8].article_url.url"
              :id="articles[8].id[0]"
              :date="articles[8].pub_date[0]"
              :showShareButton="true"
              :showDltBtn="showDeleteButton"
              :showSaveBtn="true"
              :showUnsaveBtn="showUnsaveButton"
              :ratings="articles[8].rating"
              :aggregatedRating="articles[8].aggregated_rating"
              :userReaction="articles[8].user_reaction"
              :totalArticleReactions="articles[8].article_reactions"
              :userReactionRepresentation="articles[8].users_reaction_representation"
            ></article-card>
          </a-col>
        </a-row>
      </a-col>
    </a-row> -->
    <a-row v-else :gutter="[18, 10]">
      <a-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12" class="article-grid-view-col">
        <a-row :gutter="[18, 18]" class="left-grid">
          <a-col v-if="articles[0]" :xs="24" :sm="12" :md="12" :lg="24" :xl="24">
             <article-card
              :title="articles[0].title[0]"
              :author-name="articles[0].authors[0]"
              :text="articles[0].description[0]"
              :image="articles[0].image[0]"
              :sourceLogo="articles[0].logo[0]"
              :source="articles[0].article_url.source"
              :profile-photo="'profile-pic.webp'"
              :url="articles[0].article_url.url"
              :id="articles[0].id[0]"
              :date="articles[0].pub_date[0]"
              :showShareButton="true"
              :showDltBtn="showDeleteButton"
              :showSaveBtn="true"
              :showUnsaveBtn="showUnsaveButton"
              :ratings="articles[0].rating"
              :aggregatedRating="articles[0].aggregated_rating"
              :userReaction="articles[0].user_reaction"
              :totalArticleReactions="articles[0].article_reactions"
              :userReactionRepresentation="articles[0].users_reaction_representation"
              :isInlineRatingColumn="12"
              :cardSize="true"
            ></article-card> 
          </a-col>
          <a-col v-if="articles[2]" :xs="24" :sm="12" :md="12" :lg="24" :xl="12">
             <article-card
              :title="articles[2].title[0]"
              :author-name="articles[2].authors[0]"
              :text="articles[2].description[0]"
              :image="articles[2].image[0]"
              :sourceLogo="articles[2].logo[0]"
              :source="articles[2].article_url.source"
              :profile-photo="'profile-pic.webp'"
              :url="articles[2].article_url.url"
              :id="articles[2].id[0]"
              :date="articles[2].pub_date[0]"
              :showShareButton="true"
              :showDltBtn="showDeleteButton"
              :showSaveBtn="true"
              :showUnsaveBtn="showUnsaveButton"
              :ratings="articles[2].rating"
              :aggregatedRating="articles[2].aggregated_rating"
              :userReaction="articles[2].user_reaction"
              :totalArticleReactions="articles[2].article_reactions"
              :userReactionRepresentation="articles[2].users_reaction_representation"
            ></article-card> 
          </a-col>
          <a-col v-if="articles[4]" :xs="24" :sm="12" :md="24" :lg="24" :xl="12">
            <article-card
              :title="articles[4].title[0]"
              :author-name="articles[4].authors[0]"
              :text="articles[4].description[0]"
              :image="articles[4].image[0]"
              :sourceLogo="articles[4].logo[0]"
              :source="articles[4].article_url.source"
              :profile-photo="'profile-pic.webp'"
              :url="articles[4].article_url.url"
              :id="articles[4].id[0]"
              :date="articles[4].pub_date[0]"
              :showShareButton="true"
              :showDltBtn="showDeleteButton"
              :showSaveBtn="true"
              :showUnsaveBtn="showUnsaveButton"
              :ratings="articles[4].rating"
              :aggregatedRating="articles[4].aggregated_rating"
              :userReaction="articles[4].user_reaction"
              :totalArticleReactions="articles[4].article_reactions"
              :userReactionRepresentation="articles[4].users_reaction_representation"
            ></article-card>
          </a-col>
          <a-col v-if="articles[6]" :xs="24" :sm="12" :md="12" :lg="24" :xl="24">
            <article-card
              :title="articles[6].title[0]"
              :author-name="articles[6].authors[0]"
              :text="articles[6].description[0]"
              :image="articles[6].image[0]"
              :sourceLogo="articles[6].logo[0]"
              :source="articles[6].article_url.source"
              :profile-photo="'profile-pic.webp'"
              :url="articles[6].article_url.url"
              :id="articles[6].id[0]"
              :date="articles[6].pub_date[0]"
              :showShareButton="true"
              :showDltBtn="showDeleteButton"
              :showSaveBtn="true"
              :showUnsaveBtn="showUnsaveButton"
              :ratings="articles[6].rating"
              :aggregatedRating="articles[6].aggregated_rating"
              :userReaction="articles[6].user_reaction"
              :totalArticleReactions="articles[6].article_reactions"
              :userReactionRepresentation="articles[6].users_reaction_representation"
              :isInlineRatingColumn="12"
              :cardSize="true"
            ></article-card>
          </a-col>
          <a-col v-if="articles[8]" :xs="24" :sm="12" :md="12" :lg="24" :xl="12">
            <article-card
              :title="articles[8].title[0]"
              :author-name="articles[8].authors[0]"
              :text="articles[8].description[0]"
              :image="articles[8].image[0]"
              :sourceLogo="articles[8].logo[0]"
              :source="articles[8].article_url.source"
              :profile-photo="'profile-pic.webp'"
              :url="articles[8].article_url.url"
              :id="articles[8].id[0]"
              :date="articles[8].pub_date[0]"
              :showShareButton="true"
              :showDltBtn="showDeleteButton"
              :showSaveBtn="true"
              :showUnsaveBtn="showUnsaveButton"
              :ratings="articles[8].rating"
              :aggregatedRating="articles[8].aggregated_rating"
              :userReaction="articles[8].user_reaction"
              :totalArticleReactions="articles[8].article_reactions"
              :userReactionRepresentation="articles[8].users_reaction_representation"
            ></article-card>
          </a-col>
          <a-col v-if="articles[10]" :xs="24" :sm="12" :md="24" :lg="24" :xl="12">
            <article-card
              :title="articles[10].title[0]"
              :author-name="articles[10].authors[0]"
              :text="articles[10].description[0]"
              :image="articles[10].image[0]"
              :sourceLogo="articles[10].logo[0]"
              :source="articles[10].article_url.source"
              :profile-photo="'profile-pic.webp'"
              :url="articles[10].article_url.url"
              :id="articles[10].id[0]"
              :date="articles[10].pub_date[0]"
              :showShareButton="true"
              :showDltBtn="showDeleteButton"
              :showSaveBtn="true"
              :showUnsaveBtn="showUnsaveButton"
              :ratings="articles[10].rating"
              :aggregatedRating="articles[10].aggregated_rating"
              :userReaction="articles[10].user_reaction"
              :totalArticleReactions="articles[10].article_reactions"
              :userReactionRepresentation="articles[10].users_reaction_representation"
            ></article-card>
          </a-col>
          <a-col v-if="articles[12]" :xs="24" :sm="12" :md="12" :lg="24" :xl="24">
            <article-card
             :title="articles[12].title[0]"
             :author-name="articles[12].authors[0]"
             :text="articles[12].description[0]"
             :image="articles[12].image[0]"
             :sourceLogo="articles[12].logo[0]"
             :source="articles[12].article_url.source"
             :profile-photo="'profile-pic.webp'"
             :url="articles[12].article_url.url"
             :id="articles[12].id[0]"
             :date="articles[12].pub_date[0]"
             :showShareButton="true"
             :showDltBtn="showDeleteButton"
             :showSaveBtn="true"
             :showUnsaveBtn="showUnsaveButton"
             :ratings="articles[12].rating"
             :aggregatedRating="articles[12].aggregated_rating"
             :userReaction="articles[12].user_reaction"
             :totalArticleReactions="articles[12].article_reactions"
             :userReactionRepresentation="articles[12].users_reaction_representation"
             :isInlineRatingColumn="12"
             :cardSize="true"
           ></article-card> 
         </a-col>
         <a-col v-if="articles[14]" :xs="24" :sm="12" :md="12" :lg="24" :xl="12">
          <article-card
            :title="articles[14].title[0]"
            :author-name="articles[14].authors[0]"
            :text="articles[14].description[0]"
            :image="articles[14].image[0]"
            :sourceLogo="articles[14].logo[0]"
            :source="articles[14].article_url.source"
            :profile-photo="'profile-pic.webp'"
            :url="articles[14].article_url.url"
            :id="articles[14].id[0]"
            :date="articles[14].pub_date[0]"
            :showShareButton="true"
            :showDltBtn="showDeleteButton"
            :showSaveBtn="true"
            :showUnsaveBtn="showUnsaveButton"
            :ratings="articles[14].rating"
            :aggregatedRating="articles[14].aggregated_rating"
            :userReaction="articles[14].user_reaction"
            :totalArticleReactions="articles[14].article_reactions"
            :userReactionRepresentation="articles[14].users_reaction_representation"
          ></article-card>
        </a-col>
        <a-col v-if="articles[16]" :xs="24" :sm="12" :md="24" :lg="24" :xl="12">
          <article-card
            :title="articles[16].title[0]"
            :author-name="articles[16].authors[0]"
            :text="articles[16].description[0]"
            :image="articles[16].image[0]"
            :sourceLogo="articles[16].logo[0]"
            :source="articles[16].article_url.source"
            :profile-photo="'profile-pic.webp'"
            :url="articles[16].article_url.url"
            :id="articles[16].id[0]"
            :date="articles[16].pub_date[0]"
            :showShareButton="true"
            :showDltBtn="showDeleteButton"
            :showSaveBtn="true"
            :showUnsaveBtn="showUnsaveButton"
            :ratings="articles[16].rating"
            :aggregatedRating="articles[16].aggregated_rating"
            :userReaction="articles[16].user_reaction"
            :totalArticleReactions="articles[16].article_reactions"
            :userReactionRepresentation="articles[16].users_reaction_representation"
          ></article-card>
        </a-col>
        </a-row>
      </a-col>
      <a-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12" class="article-grid-view-col">
        <a-row :gutter="[18, 18]" class="right-grid">
          <a-col v-if="articles[1]" :xs="24" :sm="12" :md="12" :lg="24" :xl="12">
            <article-card
              :title="articles[1].title[0]"
              :author-name="articles[1].authors[0]"
              :text="articles[1].description[0]"
              :image="articles[1].image[0]"
              :sourceLogo="articles[1].logo[0]"
              :source="articles[1].article_url.source"
              :profile-photo="'profile-pic.webp'"
              :url="articles[1].article_url.url"
              :id="articles[1].id[0]"
              :date="articles[1].pub_date[0]"
              :showShareButton="true"
              :showDltBtn="showDeleteButton"
              :showSaveBtn="true"
              :showUnsaveBtn="showUnsaveButton"
              :ratings="articles[1].rating"
              :aggregatedRating="articles[1].aggregated_rating"
              :userReaction="articles[1].user_reaction"
              :totalArticleReactions="articles[1].article_reactions"
              :userReactionRepresentation="articles[1].users_reaction_representation"
            ></article-card>
          </a-col>
          <a-col v-if="articles[3]" :xs="24" :sm="12" :md="12" :lg="24" :xl="12">
            <article-card
              :title="articles[3].title[0]"
              :author-name="articles[3].authors[0]"
              :text="articles[3].description[0]"
              :image="articles[3].image[0]"
              :sourceLogo="articles[3].logo[0]"
              :source="articles[3].article_url.source"
              :profile-photo="'profile-pic.webp'"
              :url="articles[3].article_url.url"
              :id="articles[3].id[0]"
              :date="articles[3].pub_date[0]"
              :showShareButton="true"
              :showDltBtn="showDeleteButton"
              :showSaveBtn="true"
              :showUnsaveBtn="showUnsaveButton"
              :ratings="articles[3].rating"
              :aggregatedRating="articles[3].aggregated_rating"
              :userReaction="articles[3].user_reaction"
              :totalArticleReactions="articles[3].article_reactions"
              :userReactionRepresentation="articles[3].users_reaction_representation"
            ></article-card>
          </a-col>
          <a-col v-if="articles[5]" :xs="24" :sm="12" :md="24" :lg="24" :xl="24">
            <article-card
              :title="articles[5].title[0]"
              :author-name="articles[5].authors[0]"
              :text="articles[5].description[0]"
              :image="articles[5].image[0]"
              :sourceLogo="articles[5].logo[0]"
              :source="articles[5].article_url.source"
              :profile-photo="'profile-pic.webp'"
              :url="articles[5].article_url.url"
              :id="articles[5].id[0]"
              :date="articles[5].pub_date[0]"
              :showShareButton="true"
              :showDltBtn="showDeleteButton"
              :showSaveBtn="true"
              :showUnsaveBtn="showUnsaveButton"
              :ratings="articles[5].rating"
              :aggregatedRating="articles[5].aggregated_rating"
              :userReaction="articles[5].user_reaction"
              :totalArticleReactions="articles[5].article_reactions"
              :userReactionRepresentation="articles[5].users_reaction_representation"
              :isInlineRatingColumn="12"
              :cardSize="true"
            ></article-card>
          </a-col>
          <a-col v-if="articles[7]" :xs="24" :sm="12" :md="12" :lg="24" :xl="12">
            <article-card
              :title="articles[7].title[0]"
              :author-name="articles[7].authors[0]"
              :text="articles[7].description[0]"
              :image="articles[7].image[0]"
              :sourceLogo="articles[7].logo[0]"
              :source="articles[7].article_url.source"
              :profile-photo="'profile-pic.webp'"
              :url="articles[7].article_url.url"
              :id="articles[7].id[0]"
              :date="articles[7].pub_date[0]"
              :showShareButton="true"
              :showDltBtn="showDeleteButton"
              :showSaveBtn="true"
              :showUnsaveBtn="showUnsaveButton"
              :ratings="articles[7].rating"
              :aggregatedRating="articles[7].aggregated_rating"
              :userReaction="articles[7].user_reaction"
              :totalArticleReactions="articles[7].article_reactions"
              :userReactionRepresentation="articles[7].users_reaction_representation"
            ></article-card>
          </a-col>
          <a-col v-if="articles[9]" :xs="24" :sm="12" :md="12" :lg="24" :xl="12">
            <article-card
              :title="articles[9].title[0]"
              :author-name="articles[9].authors[0]"
              :text="articles[9].description[0]"
              :image="articles[9].image[0]"
              :sourceLogo="articles[9].logo[0]"
              :source="articles[9].article_url.source"
              :profile-photo="'profile-pic.webp'"
              :url="articles[9].article_url.url"
              :id="articles[9].id[0]"
              :date="articles[9].pub_date[0]"
              :showShareButton="true"
              :showDltBtn="showDeleteButton"
              :showSaveBtn="true"
              :showUnsaveBtn="showUnsaveButton"
              :ratings="articles[9].rating"
              :aggregatedRating="articles[9].aggregated_rating"
              :userReaction="articles[9].user_reaction"
              :totalArticleReactions="articles[9].article_reactions"
              :userReactionRepresentation="articles[9].users_reaction_representation"
            ></article-card>
          </a-col>
          <a-col v-if="articles[11]" :xs="24" :sm="12" :md="24" :lg="24" :xl="24">
            <article-card
              :title="articles[11].title[0]"
              :author-name="articles[11].authors[0]"
              :text="articles[11].description[0]"
              :image="articles[11].image[0]"
              :sourceLogo="articles[11].logo[0]"
              :source="articles[11].article_url.source"
              :profile-photo="'profile-pic.webp'"
              :url="articles[11].article_url.url"
              :id="articles[11].id[0]"
              :date="articles[11].pub_date[0]"
              :showShareButton="true"
              :showDltBtn="showDeleteButton"
              :showSaveBtn="true"
              :showUnsaveBtn="showUnsaveButton"
              :ratings="articles[11].rating"
              :aggregatedRating="articles[11].aggregated_rating"
              :userReaction="articles[11].user_reaction"
              :totalArticleReactions="articles[11].article_reactions"
              :userReactionRepresentation="articles[11].users_reaction_representation"
              :isInlineRatingColumn="12"
              :cardSize="true"
            ></article-card>
          </a-col>
          <a-col v-if="articles[13]" :xs="24" :sm="12" :md="12" :lg="24" :xl="12">
            <article-card
              :title="articles[13].title[0]"
              :author-name="articles[13].authors[0]"
              :text="articles[13].description[0]"
              :image="articles[13].image[0]"
              :sourceLogo="articles[13].logo[0]"
              :source="articles[13].article_url.source"
              :profile-photo="'profile-pic.webp'"
              :url="articles[13].article_url.url"
              :id="articles[13].id[0]"
              :date="articles[13].pub_date[0]"
              :showShareButton="true"
              :showDltBtn="showDeleteButton"
              :showSaveBtn="true"
              :showUnsaveBtn="showUnsaveButton"
              :ratings="articles[13].rating"
              :aggregatedRating="articles[13].aggregated_rating"
              :userReaction="articles[13].user_reaction"
              :totalArticleReactions="articles[13].article_reactions"
              :userReactionRepresentation="articles[13].users_reaction_representation"
            ></article-card>
          </a-col>
          <a-col v-if="articles[15]" :xs="24" :sm="12" :md="12" :lg="24" :xl="12">
            <article-card
              :title="articles[15].title[0]"
              :author-name="articles[15].authors[0]"
              :text="articles[15].description[0]"
              :image="articles[15].image[0]"
              :sourceLogo="articles[15].logo[0]"
              :source="articles[15].article_url.source"
              :profile-photo="'profile-pic.webp'"
              :url="articles[15].article_url.url"
              :id="articles[15].id[0]"
              :date="articles[15].pub_date[0]"
              :showShareButton="true"
              :showDltBtn="showDeleteButton"
              :showSaveBtn="true"
              :showUnsaveBtn="showUnsaveButton"
              :ratings="articles[15].rating"
              :aggregatedRating="articles[15].aggregated_rating"
              :userReaction="articles[15].user_reaction"
              :totalArticleReactions="articles[15].article_reactions"
              :userReactionRepresentation="articles[15].users_reaction_representation"
            ></article-card>
          </a-col>
          <a-col v-if="articles[17]" :xs="24" :sm="12" :md="24" :lg="24" :xl="24">
            <article-card
              :title="articles[17].title[0]"
              :author-name="articles[17].authors[0]"
              :text="articles[17].description[0]"
              :image="articles[17].image[0]"
              :sourceLogo="articles[17].logo[0]"
              :source="articles[17].article_url.source"
              :profile-photo="'profile-pic.webp'"
              :url="articles[17].article_url.url"
              :id="articles[17].id[0]"
              :date="articles[17].pub_date[0]"
              :showShareButton="true"
              :showDltBtn="showDeleteButton"
              :showSaveBtn="true"
              :showUnsaveBtn="showUnsaveButton"
              :ratings="articles[17].rating"
              :aggregatedRating="articles[17].aggregated_rating"
              :userReaction="articles[17].user_reaction"
              :totalArticleReactions="articles[17].article_reactions"
              :userReactionRepresentation="articles[17].users_reaction_representation"
              :isInlineRatingColumn="12"
              :cardSize="true"
            ></article-card>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </template>
  </div>

  <subscription-modal 
    :visible="visibleSubscriptionModal"
    :visibleSignUp="visibleSubscriptionModal" 
    @cancel="visibleSubscriptionModal = false"
    :footer="null"
  ></subscription-modal>

</template>

<script>
import SubscriptionModal from "../../components/Auth/AccessModal.vue"
import ArticleCard from "../BaseComponents/ArticleCard.vue"
import ArticleCardSkeleton from "../BaseComponents/ArticleCardSkeleton.vue"

export default {
  props: {
    activeSection: String
  },
  components: {
    ArticleCard,
    SubscriptionModal,
    ArticleCardSkeleton
  },
  data() {
    return {
      showDeleteButton: false,
      showUnsaveButton: false,
      showSavedArticlesButton: true,
      visibleSubscriptionModal: false,
    };
  },
  computed: {
    mobileView() {
      return this.$store.getters["discover/getSearchedArticles"];
    },
    desktopSkeleton() {
      const insight = this.$store.getters["discover/getInsightsSkeletonLoader"];
      const news = this.$store.getters["discover/getPDBSkeletonLoader"];
      if (this.activeSection === 'Trending News') {
        return news
      }
      return insight;
    },
    searchingArticles() {
      if (window.innerWidth <= 576) {
        return this.$store.getters["discover/getPDBSkeletonLoader"];
      }
      return this.desktopSkeleton;
    },
    desktopView() {
      const newsArticles = this.$store.getters["discover/getSearchedArticles"];
      const insights = this.$store.getters["discover/getInsights"];
      if(this.activeSection === 'Trending News'){
        return newsArticles
      }
      return insights;
    },
    articles(){
      if(window.innerWidth <= 576){
        return this.mobileView;
      }
      return this.desktopView;
    }
  },
  methods: {
    showSubscriptionModal(){
      this.visibleSubscriptionModal = true;
      this.$store.commit('profile/setSubscriptionModalVisisblity', false)
    },
  }
};
</script>
<style lang="scss">
.article-grid-view {
  .ant-row {
    margin-bottom: 2rem;
    .article-grid-view-col {
      height: fit-content;
    }
  }
  .article-card {
    height: 100%;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5cb !important;
    padding-bottom: 0.5rem;
  }

  .right-grid {
    height: 100%;
    .card-text {
      text-overflow: ellipsis;
    }
    .card-heading {
      font-size: 1.6rem;
      text-overflow: ellipsis;
    }
  }
  .message {
    font-size: 2rem;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 20rem;
    .ant-btn {
      border: none;
      font-size: 1.6rem;
      font-family: $font-secondary-bold;
      cursor: pointer;
      background-color: $color-primary;
      color: $color-white;
      transition: all 0.2s;
      height: 4rem;
      width: 14rem;
      margin-top: 3rem;
      &:hover,
      &:active,
      &:focus {
        background-color: $color-primary;
        color: $color-white;
        border: none;
      }
      span {
        line-height: normal;
      }
    }
  }
  .sidebar-search-filter {
    position: sticky;
    top: 12.6rem;
  }
}
</style>
